import { Popover } from '@headlessui/react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { cn } from 'helpers/utils';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import Select, { CSSObjectWithLabel, GroupBase, OptionProps } from 'react-select';
import { ISelectOption } from 'types';

interface IProps {
    isMultiSelect?: true;
    filterOption?: any;
    selectedOptions: ISelectOption[];
    options: ISelectOption[];
    setSelectedOptions: (option: ISelectOption[]) => void;
    placeholder: string;
    isLoading: boolean;
    size?: 'md' | 'lg';
    closeMenuOnSelect?: boolean;
}

const SearchableSelectField: React.FC<IProps> = ({
    options,
    isLoading,
    size = 'md',
    placeholder,
    filterOption,
    isMultiSelect,
    selectedOptions,
    setSelectedOptions,
    closeMenuOnSelect = false,
}) => {
    const [referenceElement, setReferenceElement] = useState<any>();
    const [popperElement, setPopperElement] = useState<any>();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });

    return (
        <Popover>
            {(isOpen) => (
                <>
                    <Popover.Button
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        ref={setReferenceElement}
                        className="border-none outline-none"
                    >
                        <div
                            className={cn(
                                'relative  h-[41px] pl-2 pr-1 flex items-center text-sm text-[#909090] border border-gray_300 rounded-lg',
                                size === 'md' ? 'w-[150px]' : 'w-[210px]',
                            )}
                            style={{
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            }}
                        >
                            <div>
                                {selectedOptions.length > 0 && (
                                    <div className="flex items-center">
                                        <span className="absolute -top-[9px] left-2 text-xs bg-white px-1">
                                            {placeholder}
                                        </span>
                                        <p
                                            className="ml-0.5 text-lighter-gray px-0.5 truncate text-left rounded-sm"
                                            style={{ width: size === 'md' ? 60 : 150 }}
                                        >
                                            {options.find(
                                                (o) => o.value === selectedOptions[0]?.value,
                                            )?.label ?? ''}
                                        </p>
                                    </div>
                                )}
                                <span
                                    className={cn(
                                        'absolute duration-75',
                                        selectedOptions.length > 0
                                            ? ' -top-[9px] left-2 text-xs bg-white px-1'
                                            : 'top-2.5 left-3',
                                    )}
                                >
                                    {placeholder}
                                </span>
                            </div>
                            <div className="ml-auto flex gap-0.5 items-center">
                                {selectedOptions.length > 1 && (
                                    <p className="ml-auto text-[rgba(0,0,0,0.7)] text-xs truncate px-0.5 rounded-sm">
                                        +{selectedOptions.length - 1}
                                    </p>
                                )}
                                <ArrowIcon
                                    style={{
                                        transition: '0.4s',
                                        transform: isOpen.open ? 'rotate(180deg)' : 'rotate(0deg)',
                                    }}
                                />
                            </div>
                        </div>
                    </Popover.Button>
                    {ReactDOM.createPortal(
                        <Popover.Panel
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className="z-[80]"
                        >
                            <Select
                                isSearchable
                                isMulti={isMultiSelect}
                                isLoading={isLoading}
                                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                options={options.filter((o) => o.value)}
                                onChange={(newValue) => {
                                    if (isMultiSelect)
                                        setSelectedOptions(newValue as ISelectOption[]);
                                    else {
                                        setSelectedOptions(newValue ? [newValue as any] : []);
                                    }
                                    if (closeMenuOnSelect) referenceElement.click();
                                }}
                                isClearable={true}
                                filterOption={filterOption}
                                value={selectedOptions}
                                menuIsOpen={true}
                                styles={getSelectStyles(size)}
                                closeMenuOnSelect={false}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                }}
                                className={cn(size === 'md' ? 'w-[150px]' : 'w-[210px]')}
                                classNamePrefix="select"
                            />
                        </Popover.Panel>,
                        document.body,
                    )}
                </>
            )}
        </Popover>
    );
};

const getSelectStyles = (size: 'md' | 'lg') => ({
    input: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        fontFamily: 'inherit',
    }),
    control: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        borderRadius: 0,
        fontSize: '14px',
        minWidth: size === 'md' ? '150px' : '210px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        backgroundColor: 'white',
        borderBottom: '1px solid #C6C6C6',
        width: size === 'md' ? '150px' : '210px',
        border: 'none',
        fontFamily: 'inherit',
    }),
    multiValue: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        maxWidth: size === 'md' ? '150px' : '210px',
    }),
    option: (baseStyles: CSSObjectWithLabel, state: OptionProps<any, true, GroupBase<any>>) => ({
        ...baseStyles,
        cursor: 'pointer',
        fontSize: '14px',
        backgroundColor: state.isSelected ? '#8D8D8D' : 'inherit',
        '&:hover': {
            backgroundColor: state.isSelected ? '#8D8D8D' : '#F4F4F4',
        },
    }),
    menuPortal: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
    }),
    menu: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        marginTop: 0,
        borderRadius: 0,
        border: 'none',
        borderTop: 'none',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    }),
});

export default SearchableSelectField;
