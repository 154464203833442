import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { Popover } from '@headlessui/react';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/cancel.svg';
import { cn } from 'helpers/utils';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

interface FilterDateRangePickerProps {
    label: string;
    handleSelect: (value: RangeKeyDict | null) => void;
    startDate: string;
    endDate: string;
}

const FilterDateRangePicker = ({
    label,
    handleSelect,
    startDate,
    endDate,
}: FilterDateRangePickerProps) => {
    const [referenceElement, setReferenceElement] = useState<any>();
    const [popperElement, setPopperElement] = useState<any>();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });

    const fieldLabel = useMemo(() => {
        if (startDate && endDate) {
            return (
                moment(startDate).format('YYYY/MM/DD') +
                ' ~ ' +
                moment(endDate).format('YYYY/MM/DD')
            );
        }
        return label;
    }, [label, startDate, endDate]);
    return (
        <div className="relative">
            <Popover>
                {(state) => (
                    <>
                        <Popover.Button
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            ref={setReferenceElement}
                            className="border-none outline-none"
                        >
                            <div
                                className={cn(
                                    'w-[204px] h-[42px] border border-gray_300 py-[10px] rounded-lg pl-2 pr-1 flex items-center gap-x-1  cursor-pointer ',
                                    state.open
                                        ? 'border-[#1976d2] text-[#1976d2] stroke-[#1976d2]'
                                        : 'text-[#909090] stroke-[#344054] hover:border-[#000000de]',
                                )}
                                style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
                            >
                                <CalendarIcon stroke="inherit" />
                                <p className="text-xs font-normal">{fieldLabel}</p>
                                {startDate && (
                                    <ClearIcon
                                        className="hover:opacity-70"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSelect(null);
                                        }}
                                    />
                                )}
                            </div>
                        </Popover.Button>
                        {ReactDOM.createPortal(
                            <Popover.Panel
                                ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper}
                                className="z-[80] bg-red-500"
                            >
                                <div className={cn('relative')}>
                                    <DateRangePicker
                                        onChange={handleSelect}
                                        months={2}
                                        ranges={[
                                            {
                                                startDate: startDate
                                                    ? new Date(startDate)
                                                    : new Date(),
                                                endDate: endDate ? new Date(endDate) : new Date(),
                                                key: 'selection',
                                            },
                                        ]}
                                        direction="vertical"
                                        className="absolute top-2 -translate-x-[120px] shadow-lg z-10"
                                    />
                                </div>
                            </Popover.Panel>,
                            document.body,
                        )}
                    </>
                )}
            </Popover>
        </div>
    );
};

export default FilterDateRangePicker;
