import { emptyApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        postSapPortal: build.mutation<PostSapPortalApiResponse, PostSapPortalApiArg>({
            query: (queryArg) => ({ url: `/SapPortal`, method: 'POST', body: queryArg }),
        }),
        getSapPortal: build.query<GetSapPortalApiResponse, GetSapPortalApiArg>({
            query: (queryArg) => ({ url: `/SapPortal`, params: { userGroups: queryArg } }),
        }),
        getSapPortalSearchBar: build.query<
            GetSapPortalSearchBarApiResponse,
            GetSapPortalSearchBarApiArg
        >({
            query: (queryArg) => ({
                url: `/SapPortal/searchBar`,
                params: { invoiceType: queryArg.invoiceType, userGroups: queryArg.userGroups },
            }),
        }),
        postSapPortalDownload: build.mutation<
            PostSapPortalDownloadApiResponse,
            PostSapPortalDownloadApiArg
        >({
            query: (queryArg) => ({ url: `/SapPortal/download`, method: 'POST', body: queryArg }),
        }),
        postSapPortalSendEmail: build.mutation<
            PostSapPortalSendEmailApiResponse,
            PostSapPortalSendEmailApiArg
        >({
            query: (queryArg) => ({ url: `/SapPortal/sendEmail`, method: 'POST', body: queryArg }),
        }),
        postSapPortalExcelExport: build.mutation<
            PostSapPortalExcelExportApiResponse,
            PostSapPortalExcelExportApiArg
        >({
            query: (queryArg) => ({
                url: `/SapPortal/excelExport`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSapPortalBusinessPartner: build.mutation<
            PostSapPortalBusinessPartnerApiResponse,
            PostSapPortalBusinessPartnerApiArg
        >({
            query: (queryArg) => ({
                url: `/SapPortal/businessPartner`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSapPortalExcelExportBp: build.mutation<
            PostSapPortalExcelExportBpApiResponse,
            PostSapPortalExcelExportBpApiArg
        >({
            query: (queryArg) => ({
                url: `/SapPortal/excelExportBp`,
                method: 'POST',
                body: queryArg,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as allApis };
export type PostSapPortalApiResponse = /** status 200 Success */ Documents;
export type PostSapPortalApiArg = DocumentSp;
export type GetSapPortalApiResponse = /** status 200 Success */ DocumentShortInfo;
export type GetSapPortalApiArg = string;
export type GetSapPortalSearchBarApiResponse = /** status 200 Success */ SearchBar;
export type GetSapPortalSearchBarApiArg = {
    invoiceType?: string;
    userGroups?: string;
};
export type PostSapPortalDownloadApiResponse = unknown;
export type PostSapPortalDownloadApiArg = DocumentFileSp;
export type PostSapPortalSendEmailApiResponse = unknown;
export type PostSapPortalSendEmailApiArg = SendEmailRequest;
export type PostSapPortalExcelExportApiResponse = /** status 200 Success */ DocumentExcelExport;
export type PostSapPortalExcelExportApiArg = DocumentSp;
export type PostSapPortalBusinessPartnerApiResponse =
    /** status 200 Success */ DocumentsBusinessPartner;
export type PostSapPortalBusinessPartnerApiArg = DocumentBusinessPartnerSp;
export type PostSapPortalExcelExportBpApiResponse = /** status 200 Success */ DocumentExcelExport;
export type PostSapPortalExcelExportBpApiArg = DocumentBusinessPartnerSp;
export type UnitAmount = {
    value?: number;
    currency?: string | null;
    dueDate?: string | null;
    clearDate?: string | null;
    clearDateStatus?: string | null;
    iban?: string | null;
};
export type Document = {
    id?: string | null;
    companyDivisionName?: string | null;
    customerCompanyName?: string | null;
    supplierCompanyName?: string | null;
    customerCode?: string | null;
    supplierCode?: string | null;
    documentType?: string | null;
    documentDate?: string | null;
    documentNumber?: string | null;
    documentNumberToBeDisplayed?: string | null;
    documentNumberSAP?: string | null;
    idsdi?: string | null;
    ceilingStatus?: string | null;
    processingStatus?: string | null;
    processingStatusDescription?: string | null;
    sdiReplyStatus?: string | null;
    sdiRejectionDesc?: string | null;
    companyCode?: string | null;
    divisionCode?: string | null;
    dataInsertedDate?: string | null;
    documentPdfStatus?: boolean;
    documentXmlStatus?: boolean;
    documentRXmlStatus?: boolean;
    totalAmount?: number;
    transfer?: string | null;
    unitAmount?: UnitAmount[] | null;
};
export type Documents = {
    totalResult?: number;
    documentList?: Document[] | null;
};
export type DocumentSp = {
    userGroups?: string | null;
    size?: number;
    invoiceType?: string | null;
    divisions?: string[] | null;
    divisionNames?: string[] | null;
    companyCode?: string | null;
    suppliers?: string[] | null;
    customers?: string[] | null;
    documentNumber?: string | null;
    documentNumberSAP?: string | null;
    documentDateFrom?: string | null;
    documentDateTo?: string | null;
    dueDateFrom?: string | null;
    dueDateTo?: string | null;
    costFrom?: number | null;
    costTo?: number | null;
    clearDateStatuses?: string[] | null;
    ceilingStatuses?: string[] | null;
    sdiReplyStatuses?: string[] | null;
    searchKey?: string | null;
    documentTypes?: string[] | null;
    searchAfter?: any[] | null;
    sortExpression?: {
        [key: string]: string;
    } | null;
};
export type StatoPlafondClient = {
    totalSizeStatoPlafondClient?: number;
    statoPlafondsClient?: string[] | null;
};
export type SdiReplyStatusClient = {
    totalSizeSdiReplyStatusClient?: number;
    sdiReplyStatusesClient?: string[] | null;
};
export type DocumentShortInfo = {
    lastInsertDate?: string | null;
    statoPlafondClient?: StatoPlafondClient;
    sdiReplyStatusClient?: SdiReplyStatusClient;
};
export type Customer = {
    customerCode?: string | null;
    customerCompanyName?: string | null;
};
export type Supplier = {
    supplierCode?: string | null;
    supplierCompanyName?: string | null;
};
export type Division = {
    divisionCode?: string | null;
    companyDivisionName?: string | null;
};
export type SdiReplyStatus = {
    sdiReplyStatusCode?: string | null;
    sdiReplyStatusName?: string | null;
};
export type CeilingStatus = {
    ceilingStatusCode?: string | null;
    ceilingStatusValue?: string | null;
};
export type DocumentType = {
    documentTypeCode?: string | null;
    documentTypeValue?: string | null;
    invoiceType?: string | null;
};
export type SearchBar = {
    customers?: Customer[] | null;
    suppliers?: Supplier[] | null;
    divisions?: Division[] | null;
    sdiReplyStatuses?: SdiReplyStatus[] | null;
    ceilingStatuses?: CeilingStatus[] | null;
    documentTypes?: DocumentType[] | null;
};
export type DocumentFileSp = {
    id?: string | null;
    fileType?: string | null;
};
export type SendEmailRequest = {
    id?: string | null;
    hasPdf?: boolean;
    recipientEmail?: string | null;
    graphToken?: string | null;
};
export type DocumentExcelExport = {
    fileName?: string | null;
    fileNameBase64?: string | null;
};
export type BankInfoItem = {
    idBank?: string | null;
    countryBank?: string | null;
    keyBank?: string | null;
    bankAccount?: string | null;
    keyContrBanks?: string | null;
    iban?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    swift?: string | null;
};
export type VatCodeItem = {
    taxtype?: string | null;
    dTaxType?: string | null;
    taxNum?: string | null;
    taxNumxl?: string | null;
};
export type MailItem = {
    ownerCamp?: string | null;
    ownerProd?: string | null;
    refAmm?: string | null;
    refComm?: string | null;
};
export type OldNumItem = {
    tipo?: string | null;
    fiCode?: string | null;
    erpCode?: string | null;
};
export type DivisionItem = {
    division?: string | null;
    dDivision?: string | null;
    mails?: MailItem;
    oldNums?: OldNumItem[] | null;
};
export type DocumentItem = {
    id?: string | null;
    name?: string | null;
    physicalPerson?: string | null;
    addressCode?: string | null;
    keyCountry?: string | null;
    postalCode?: string | null;
    street?: string | null;
    city?: string | null;
    region?: string | null;
    nCompanyPartner?: string | null;
    dnCompanyPartner?: string | null;
    businessPartner?: string | null;
    group?: string | null;
    supplier?: string | null;
    companyS?: string | null;
    repartitionType?: string | null;
    dRepartitionType?: string | null;
    cashManagGroup?: string | null;
    dCashManagGroup?: string | null;
    paymentCondS?: string | null;
    dPaymentCondS?: string | null;
    paymentModeS?: string | null;
    dPaymentModeS?: string | null;
    client?: string | null;
    attribute6C?: string | null;
    dAttribute6C?: string | null;
    attribute7C?: string | null;
    dAttribute7C?: string | null;
    companyC?: string | null;
    paymentCondC?: string | null;
    dPaymentCondC?: string | null;
    paymentModeC?: string | null;
    dPaymentModeC?: string | null;
    bankInfos?: BankInfoItem[] | null;
    vatCodes?: VatCodeItem[] | null;
    divisions?: DivisionItem[] | null;
};
export type DocumentsBusinessPartner = {
    totalResult?: number;
    lastInsertDate?: string | null;
    documentList?: DocumentItem[] | null;
};
export type DocumentBusinessPartnerSp = {
    size?: number;
    fiCode?: string | null;
    erpCode?: string | null;
    divisions?: string[] | null;
    taxNumAndNumXl?: string | null;
    searchKey?: string | null;
    sortExpression?: {
        [key: string]: string;
    } | null;
    typology?: string | null;
    excludeBusinessPartner?: boolean | null;
};
export const {
    usePostSapPortalMutation,
    useGetSapPortalQuery,
    useGetSapPortalSearchBarQuery,
    usePostSapPortalDownloadMutation,
    usePostSapPortalSendEmailMutation,
    usePostSapPortalExcelExportMutation,
    usePostSapPortalBusinessPartnerMutation,
    usePostSapPortalExcelExportBpMutation,
} = injectedRtkApi;
