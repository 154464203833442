import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import BusinessPartnerCard from 'components/business-partner/card';
import BusinessPartnerFilter from 'components/business-partner/filter';
import Button from 'components/shared/button';
import Skeleton from 'components/shared/skeleton';
import { PAGE_SIZE } from 'consts';
import { clearObject } from 'helpers/utils';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    DocumentBusinessPartnerSp,
    DocumentItem,
    usePostSapPortalBusinessPartnerMutation,
} from 'store/rtk/allApis';

const BusinessPartnerPage = () => {
    const [searchParams] = useSearchParams();
    const [documents, setDocuments] = useState<DocumentItem[]>([]);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [executeGetBusinessPartnerData, { data, isError, isLoading, isSuccess }] =
        usePostSapPortalBusinessPartnerMutation({
            fixedCacheKey: 'business-partner-data',
        });

    const filterWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isSuccess || !data || !data.documentList || isLoading) return;
        setDocuments(data.documentList);
        setIsMoreLoading(false);
    }, [data]);

    const params: DocumentBusinessPartnerSp = useMemo(() => {
        const obj = clearObject({
            size: PAGE_SIZE,
            taxNumAndNumXl: searchParams.get('taxNumAndNumXl'),
            allBPFromSAP: !!searchParams.get('allBPFromSAP'),
            sortExpression: searchParams.get('sortExpression'),
            divisions: searchParams.getAll('divisions'),
            typology: searchParams.get('typology'),
            searchKey: searchParams.get('searchKey'),
            erpCode: searchParams.get('erpCode'),
            fiCode: searchParams.get('fiCode'),
        });
        if (obj.sortExpression) {
            const [sortField, sortDirection] = obj.sortExpression.split('-');
            obj.sortExpression = {
                [sortField]: sortDirection,
            };
        }
        return obj;
    }, [searchParams]);

    const getMoreDocuments = useCallback(() => {
        setIsMoreLoading(true);
        const size = documents.length + PAGE_SIZE;
        executeGetBusinessPartnerData({ ...params, size });
    }, [params, executeGetBusinessPartnerData, documents]);

    useEffect(() => {
        setDocuments([]);
        executeGetBusinessPartnerData({ ...params });
    }, [params]);

    if (isError) {
        return (
            <div className="mt-40 w-full h-full flex flex-col items-center justify-center">
                <DangerIcon className="w-10 h-10 mb-6 fill-rose-600" />
                <p className="text-xl font-bold text-rose-600">Something went wrong!</p>
            </div>
        );
    }

    return (
        <>
            <div className="flex items-center justify-between pt-4 px-6 sticky left-0">
                <h1 className="text-[#0B121F] text-[28px] inline-block font-bold leading-9 pb-5 border-b-2 border-[#0B121F]">
                    Business Partner
                </h1>
                <div className="pb-2 flex flex-col gap-y-1">
                    <div className="font-bold text-dark-gray_main flex justify-end">
                        {isSuccess && <span>{`Elementi trovati: ${data?.totalResult ?? 0}`}</span>}
                        {isLoading && <Skeleton className="w-[180px] h-5" />}
                    </div>
                    <div className="text-xs flex items-center">
                        {isLoading && <Skeleton className="w-[300px] h-4" />}
                        {data?.lastInsertDate && (
                            <p>
                                Ultimo aggiornamento il{' '}
                                <span className="font-bold">
                                    {moment(data.lastInsertDate).format('DD MMMM YYYY, HH:mm')}
                                </span>
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="sticky top-0 left-0 bg-white z-[60] px-6">
                <div className="border-b border-gray_200" />
                <BusinessPartnerFilter filterWrapperRef={filterWrapperRef} />
            </div>
            <div className="px-6">
                {documents?.map((d, index) => (
                    <BusinessPartnerCard document={d} key={index} />
                ))}
                {isLoading && (
                    <div className="flex flex-col gap-y-4 py-3 w-full">
                        {new Array(isMoreLoading ? 2 : 8).fill(0).map((_, idx) => (
                            <Skeleton key={idx} className="h-[84px]" />
                        ))}
                    </div>
                )}
            </div>
            {documents.length !== 0 &&
                documents.length % +(params.size ?? PAGE_SIZE) === 0 &&
                !isError && (
                    <div className="sticky left-0 grid place-items-center mb-10">
                        <Button
                            type="outline"
                            onClick={getMoreDocuments}
                            isLoading={isMoreLoading}
                            label="Mostra di più"
                            className=""
                        />
                    </div>
                )}
        </>
    );
};

export default BusinessPartnerPage;
