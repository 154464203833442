import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

interface CheckBoxProps {
    label: string | React.ReactNode;
    checked: boolean;
    defaultChecked?: boolean;
    onChange: (value: boolean) => void;
}
const CheckBox = ({ label, checked, onChange, defaultChecked }: CheckBoxProps) => {
    return (
        <div className="relative">
            <FormControlLabel
                label={label}
                className="text-[#909090]"
                control={
                    <Checkbox
                        size="small"
                        onChange={(e) => onChange(e.target.checked)}
                        checked={checked}
                        defaultChecked={defaultChecked}
                        sx={{
                            marginLeft: 1,
                            '&:hover': {
                                backgroundColor: '#3a383608',
                            },
                            color: '#3a3836',
                            '&.Mui-checked': {
                                color: '#3a3836',
                            },
                        }}
                    />
                }
            />
        </div>
    );
};

export default CheckBox;
