import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

export const getGraphAccessToken = async (
    accounts: AccountInfo[],
    instance: IPublicClientApplication,
): Promise<string> => {
    const accessTokenRequest = {
        scopes: [`https://graph.microsoft.com/.default`],
        account: accounts[0],
    };
    return instance.acquireTokenSilent(accessTokenRequest).then(async (accessTokenResponse) => {
        return accessTokenResponse.accessToken;
    });
};
