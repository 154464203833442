import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { BASE_URL } from 'consts';
import { getAuthHead } from 'helpers/auth';
import { getGroupAuth } from 'helpers/auth/groupAuth';

// import { toast } from 'react-toastify';
import { rtkBaseQueryFactory } from './rtkBaseQuery';

export const axiosInstance = axios.create({
    withCredentials: false,
    baseURL: BASE_URL,
});
axiosInstance.interceptors.request.use(async (config) => {
    const header = await getAuthHead();
    const userGroups = await getGroupAuth();
    if (header?.headers) {
        config.headers.set('Authorization', header.headers.authorization);
    }
    switch (config.method) {
        case 'get':
            config.params = config.params || {};
            config.params['userGroups'] = userGroups;
            break;
        case 'post':
            config.data = config.data || {};
            config.data['userGroups'] = userGroups;
            break;
    }

    return config;
});
axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        let message = 'Error';
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data.ExceptionMessage) {
                message = error.response.data.ExceptionMessage;
            }
        } else if (typeof error === 'string') {
            message = error;
        } else {
            // Something happened in setting up the request that triggered an Error
            message = error.message;
        }
        // toast.error(message);
        console.error(message);
        return Promise.reject(error);
    },
);

export const emptyApi = createApi({
    reducerPath: 'allApis',
    baseQuery: rtkBaseQueryFactory({
        getAxiosInstance: async () => {
            return axiosInstance;
        },
    }),
    endpoints: () => ({}),
});
