import { TextField as MuiTextField, Tooltip } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import React from 'react';

interface TextFieldProps {
    type?: string;
    label: string;
    description?: string;
    width: number | string;
    value: string;
    onChange: (value: string) => void;
}
const TextField = ({
    label,
    description,
    width,
    value,
    onChange,
    type = 'text',
}: TextFieldProps) => {
    return (
        <div className="relative">
            <MuiTextField
                type={type}
                value={value}
                label={label}
                variant="outlined"
                size="small"
                onChange={(e) => onChange(e.target.value.trimStart())}
                sx={{
                    width,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#E6E6E8!important',
                        },
                        '&:hover fieldset': {
                            borderColor: '#000000de!important',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#1976d2!important',
                        },
                    },
                }}
                InputLabelProps={{ sx: { fontSize: 14, mt: '2px', color: '#909090' } }}
                InputProps={{
                    sx: {
                        borderRadius: 2,
                        height: 42,
                        fontSize: 14,
                        paddingRight: description && '14px',
                    },
                }}
            />
            {description && (
                <Tooltip title={description} enterDelay={500}>
                    <InfoIcon className="absolute w-3.5 cursor-pointer top-3.5 right-1.5" />
                </Tooltip>
            )}
        </div>
    );
};

export default TextField;
