import { ReactComponent as CheckBadge } from 'assets/icons/check-badge.svg';
import { ReactComponent as InfoBadge } from 'assets/icons/info-badge.svg';
import { ROUTES } from 'consts';
import { cn, formatPrice } from 'helpers/utils';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Document } from 'store/rtk/allApis';

import Actions from './actions';
import TableStatusBadge from './status-badge';

const statusIconMap = {
    check: <CheckBadge />,
    info: <InfoBadge />,
};

interface TableRowProps {
    document: Document;
}

const TableRow = ({ document }: TableRowProps) => {
    const { pathname } = useLocation();
    const isCustomerInvoice = pathname === ROUTES.CUSTOMER_INVOICES;
    const hasError = document.unitAmount?.some((a) => a.clearDateStatus === 'Error');

    return (
        <div
            className={cn(
                'grid grid-cols-invoice_table mb-6 px-6 py-3 gap-x-4 items-center min-w-full w-fit text-sm text-lighter-gray',
                hasError && 'bg-lightest-red',
            )}
        >
            <Actions
                documentId={document.id ?? ''}
                isCustomerInvoice={isCustomerInvoice}
                hasPdf={!!document.documentPdfStatus}
                hasXml={!!document.documentXmlStatus}
                hasRXml={!!document.documentRXmlStatus}
            />
            <span>{statusIconMap[hasError ? 'info' : 'check']}</span>
            <div className="flex flex-col">
                <span className="font-bold">
                    {document.companyCode + ' - ' + document.companyDivisionName}
                </span>
                <span>{document.documentType}</span>
                <span>{moment(document.documentDate).format('DD/MM/yyyy')}</span>
            </div>
            <div className="flex flex-col">
                <span className="font-bold">
                    {isCustomerInvoice
                        ? document.customerCompanyName
                        : document.supplierCompanyName}
                </span>
                <span>{isCustomerInvoice ? document.customerCode : document.supplierCode}</span>
            </div>
            <div className="flex flex-col">
                <span>{document.documentNumberToBeDisplayed || '-'}</span>
                <span>{document.id ? document.id.split('-').join(' ') : '-'}</span>
            </div>
            <div className="flex flex-col gap-y-3">
                <span className="inline-block py-1.5 font-semibold">
                    {document.totalAmount
                        ? `${formatPrice(document.totalAmount)} ${
                              document.unitAmount?.[0]?.currency ?? ''
                          }`
                        : '-'}
                </span>
            </div>
            <p>{document.transfer || '-'}</p>
            <div className="flex flex-col gap-y-3">
                {document.unitAmount?.map((amount, idx: number) => (
                    <TableStatusBadge
                        key={idx}
                        isCustomerInvoice={isCustomerInvoice}
                        type={amount.clearDateStatus as 'Success' | 'Warning' | 'Error'}
                    />
                ))}
            </div>
            <div className="flex flex-col">
                {document.unitAmount?.map((amount, idx: number) => (
                    <p className="py-1 flex flex-col gap-y-1" key={idx}>
                        {amount.dueDate ? (
                            <span className="text-sm">
                                {moment(amount.dueDate).format('DD/MM/yyyy')}
                            </span>
                        ) : (
                            '-'
                        )}
                        {amount.value ? (
                            <span key={idx} className="font-semibold text-xs">{`${formatPrice(
                                amount.value,
                            )} ${amount.currency ?? ''}`}</span>
                        ) : (
                            '-'
                        )}
                    </p>
                ))}
            </div>
            <div className="flex flex-col ">
                {document.unitAmount?.map((amount, idx: number) => (
                    <p className="py-1 " key={idx}>
                        {amount.iban ? <span className="text-sm">{amount.iban}</span> : '-'}
                    </p>
                ))}
            </div>
            <p>{document.idsdi || '-'}</p>
            <p>{document.ceilingStatus || '-'}</p>
            <p>{document.processingStatus || '-'}</p>
            <p>{document.sdiReplyStatus || '-'}</p>
            <p>{document.processingStatusDescription || '-'}</p>
        </div>
    );
};

export default TableRow;
