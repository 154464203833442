import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-clear.svg';
import Button from 'components/shared/button';
import { ROUTES } from 'consts';
import { clearObject, cn } from 'helpers/utils';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import {
    allApis,
    usePostSapPortalExcelExportMutation,
    usePostSapPortalMutation,
} from 'store/rtk/allApis';
import { SearchFilterType, SearchParamKeyType } from 'types';

import SearchableSelectField from '../../shared/searchable-select';
import TextField from '../../shared/text-field';
import DateRangePicker from './fields/date-range-picker';
import PriceRange from './fields/price-range';

const formDefaultValues = {
    divisions: [],
    customers: [],
    suppliers: [],
    clearDateStatuses: [],
    sdiReplyStatuses: [],
    ceilingStatuses: [],
    documentTypes: [],
    sortExpression: [],
    documentDateFrom: '',
    documentDateTo: '',
    documentNumber: '',
    companyCode: '',
    documentNumberSAP: '',
    searchKey: '',
    costFrom: '',
    costTo: '',
    dueDateFrom: '',
    dueDateTo: '',
};

const searchObjectParamKeys = [
    { key: 'searchKey', valueKey: '', type: 'default' },
    { key: 'costFrom', valueKey: '', type: 'default' },
    { key: 'costTo', valueKey: '', type: 'default' },
    { key: 'documentNumber', valueKey: '', type: 'default' },
    { key: 'documentNumberSAP', valueKey: '', type: 'default' },
    { key: 'companyCode', valueKey: '', type: 'default' },
    { key: 'documentDateFrom', valueKey: '', type: 'date' },
    { key: 'documentDateTo', valueKey: '', type: 'date' },
    { key: 'dueDateFrom', valueKey: '', type: 'date' },
    { key: 'dueDateTo', valueKey: '', type: 'date' },
    { key: 'divisions', valueKey: 'divisionCode', type: 'dynamic-options' },
    { key: 'customers', valueKey: 'customerCode', type: 'dynamic-options' },
    { key: 'suppliers', valueKey: 'supplierCode', type: 'dynamic-options' },
    { key: 'sdiReplyStatuses', valueKey: 'sdiReplyStatusCode', type: 'dynamic-options' },
    { key: 'ceilingStatuses', valueKey: 'ceilingStatusCode', type: 'dynamic-options' },
    { key: 'documentTypes', valueKey: 'documentTypeCode', type: 'dynamic-options' },
    { key: 'clearDateStatuses', valueKey: 'value', type: 'static-options' },
    { key: 'sortExpression', valueKey: 'value', type: 'sort' },
];

interface InvoicesFilterProps {
    filterWrapperRef: React.RefObject<HTMLDivElement>;
}

const InvoicesFilter = ({ filterWrapperRef }: InvoicesFilterProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname } = useLocation();
    const isCustomerInvoice = pathname === ROUTES.CUSTOMER_INVOICES;
    const [executeExcelExport] = usePostSapPortalExcelExportMutation();
    const [, { data: documents, isLoading: isDocumentsLoading }] = usePostSapPortalMutation({
        fixedCacheKey: 'sap-portal-data',
    });
    const { isLoading, data, isSuccess } = allApis.useGetSapPortalSearchBarQuery(
        {
            invoiceType: isCustomerInvoice ? 'C' : 'S',
        },
        { refetchOnMountOrArgChange: true },
    );

    const { handleSubmit, watch, setValue, reset } = useForm<SearchFilterType>({
        defaultValues: formDefaultValues,
    });

    const options = useMemo(() => {
        return {
            divisions:
                data?.divisions
                    ?.map((d) => ({
                        value: d.divisionCode ?? '',
                        label: d.companyDivisionName ?? '',
                    }))
                    .filter((d) => d.value && d.label)
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
            suppliers:
                data?.suppliers
                    ?.map((s) => ({
                        value: s.supplierCode ?? '',
                        label: s.supplierCompanyName ?? '',
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
            customers:
                data?.customers
                    ?.map((c) => ({
                        value: c.customerCode ?? '',
                        label: c.customerCompanyName ?? '',
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
            sdiReplyStatuses:
                data?.sdiReplyStatuses
                    ?.map((s) => ({
                        value: s.sdiReplyStatusCode ?? '',
                        label: s.sdiReplyStatusName ?? '',
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
            ceilingStatuses:
                data?.ceilingStatuses
                    ?.map((c) => ({
                        value: c.ceilingStatusCode ?? '',
                        label: c.ceilingStatusValue ?? '',
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
            documentTypes:
                data?.documentTypes
                    ?.map((d) => ({
                        value: d.documentTypeCode ?? '',
                        label: d.documentTypeValue ?? '',
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
            clearDateStatuses: [
                { value: 'Success', label: 'Pareggiata' },
                { value: 'Warning', label: 'Non pareggiata' },
                { value: 'Error', label: isCustomerInvoice ? 'Scaduta' : 'Da pagare' },
            ],
            sortExpressions: [
                {
                    value: 'documentDate-asc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowUpIcon /> Data doc
                        </div>
                    ),
                },
                {
                    value: 'documentDate-desc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowDownIcon />
                            Data doc
                        </div>
                    ),
                },
                {
                    value: 'documentNumberSAP-asc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowUpIcon /> Numero doc. SAP
                        </div>
                    ),
                },
                {
                    value: 'documentNumberSAP-desc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowDownIcon />
                            Numero doc. SAP
                        </div>
                    ),
                },
                {
                    value: 'totalAmount-asc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowUpIcon /> Importo
                        </div>
                    ),
                },
                {
                    value: 'totalAmount-desc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowDownIcon />
                            Importo
                        </div>
                    ),
                },
                {
                    value: 'dueDate-asc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowUpIcon /> Scadenza
                        </div>
                    ),
                },
                {
                    value: 'dueDate-desc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowDownIcon />
                            Scadenza
                        </div>
                    ),
                },
            ],
        };
    }, [data]);

    const costFrom = watch('costFrom');
    const costTo = watch('costTo');
    const searchKey = watch('searchKey');
    const divisions = watch('divisions');
    const customers = watch('customers');
    const suppliers = watch('suppliers');
    const companyCode = watch('companyCode');
    const documentNumberSAP = watch('documentNumberSAP');
    const sdiReplyStatuses = watch('sdiReplyStatuses');
    const ceilingStatuses = watch('ceilingStatuses');
    const sortExpression = watch('sortExpression');
    const clearDateStatuses = watch('clearDateStatuses');
    const documentTypes = watch('documentTypes');
    const documentNumber = watch('documentNumber');
    const documentDateFrom = watch('documentDateFrom');
    const documentDateTo = watch('documentDateTo');
    const dueDateFrom = watch('dueDateFrom');
    const dueDateTo = watch('dueDateTo');

    const generateQuery = useCallback((data: SearchFilterType) => {
        const clearedObj = clearObject(data);
        const query: any = {};

        searchObjectParamKeys.forEach((obj) => {
            if (clearedObj[obj.key]) {
                let value;
                switch (obj.type) {
                    case 'default':
                        value = clearedObj[obj.key];
                        break;
                    case 'date':
                        value = moment(clearedObj[obj.key]).format('YYYY-MM-DD');
                        break;
                    case 'dynamic-options':
                    case 'static-options':
                    case 'sort':
                        value = clearedObj[obj.key].map((v: any) =>
                            typeof v[obj.valueKey] === 'string'
                                ? v[obj.valueKey].trim()
                                : v[obj.valueKey],
                        );
                        break;
                }
                query[obj.key] = value;
            }
        });
        return query;
    }, []);

    const onSubmit = useCallback(
        (data: SearchFilterType) => {
            const query = generateQuery(data);
            setSearchParams(createSearchParams(query));
        },
        [searchParams],
    );

    useEffect(() => {
        if (isSuccess && data) {
            fillSearchFields();
        }
    }, [data, isSuccess]);

    useEffect(() => {
        const onSearchKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                handleSubmit(onSubmit)();
            }
        };
        document.addEventListener('keydown', onSearchKeyPress);
        return () => document.removeEventListener('keydown', onSearchKeyPress);
    }, []);

    const fillSearchFields = useCallback(() => {
        searchObjectParamKeys.forEach((obj) => {
            switch (obj.type) {
                case 'default':
                case 'date':
                    const paramValue = searchParams.get(obj.key);
                    if (paramValue) {
                        setValue(obj.key as SearchParamKeyType, paramValue);
                    }
                    break;
                case 'dynamic-options':
                    const paramValuesDynamic = searchParams.getAll(obj.key);
                    const valueDynamic = ((data as any)?.[obj.key]?.filter(
                        (v: any) => v[obj.valueKey] && paramValuesDynamic.includes(v[obj.valueKey]),
                    ) ?? []) as any;
                    if (obj.key === 'sdiReplyStatuses' && paramValuesDynamic.includes('')) {
                        valueDynamic.push({
                            sdiReplyStatusCode: ' ',
                            sdiReplyStatusName: 'NESSUNO STATO',
                        });
                    }
                    setValue(obj.key as SearchParamKeyType, valueDynamic);
                    break;
                case 'static-options':
                    const paramValuesStatic = searchParams.getAll(obj.key);
                    const valueStatic = ((options as any)?.[obj.key]?.filter(
                        (v: any) => v[obj.valueKey] && paramValuesStatic.includes(v[obj.valueKey]),
                    ) ?? []) as any;
                    setValue(obj.key as SearchParamKeyType, valueStatic);
                    break;
                case 'sort':
                    const paramValueSort = searchParams.get(obj.key);
                    if (paramValueSort) {
                        setValue(obj.key as SearchParamKeyType, [{ value: paramValueSort }] as any);
                    }
                    break;
            }
        });
    }, [searchParams, data]);

    const onFilterClear = useCallback(() => {
        reset();
        setSearchParams({});
    }, [setSearchParams, reset]);

    const onExcelDownload = useCallback(
        (data: SearchFilterType) => {
            const query = generateQuery(data);

            if (documents?.totalResult && documents.totalResult > 10000) {
                query.size = documents?.totalResult;
            }

            if (query.sortExpression && query.sortExpression.length > 0) {
                const [sortField, sortDirection] = query.sortExpression[0].split('-');
                query.sortExpression = {
                    [sortField]: sortDirection,
                };
            }
            query.invoiceType = isCustomerInvoice ? 'C' : 'S';

            const executeExportPromise = executeExcelExport(query)
                .unwrap()
                .then((data) => {
                    const base64String = data.fileNameBase64 ?? '';
                    const fileName = data.fileName ?? '';

                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    const blob = new Blob([byteArray], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });

                    const url = URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    URL.revokeObjectURL(url);
                });

            toast.promise(executeExportPromise, {
                loading: 'Download in corso...',
                success: 'Download completato',
                error: 'Errore durante il download',
            });
        },
        [documents, isCustomerInvoice],
    );

    return (
        <div ref={filterWrapperRef} className="text-[#171717b3] text-sm w-full">
            <div className="flex items-start gap-x-2 justify-between py-3 border-b border-gray_200">
                <div className={cn('flex flex-wrap gap-y-3 gap-x-2')}>
                    <TextField
                        label="Full search"
                        width={280}
                        onChange={(value) => setValue('searchKey', value)}
                        value={searchKey}
                    />
                    <TextField
                        label="Codice società"
                        width={135}
                        onChange={(value) => setValue('companyCode', value)}
                        value={companyCode}
                    />
                    <SearchableSelectField
                        isMultiSelect
                        selectedOptions={divisions.map((d) => ({
                            value: d.divisionCode ?? '',
                            label: d.companyDivisionName ?? '',
                        }))}
                        setSelectedOptions={(options) => {
                            setValue(
                                'divisions',
                                options.map((o) => ({
                                    companyDivisionName: o.label?.toString(),
                                    divisionCode: o.value,
                                })),
                            );
                        }}
                        placeholder="Divisione"
                        isLoading={isLoading}
                        options={options.divisions}
                    />

                    <TextField
                        label="Numero doc. SAP"
                        width={140}
                        onChange={(value) => setValue('documentNumberSAP', value)}
                        value={documentNumberSAP}
                    />
                    {isCustomerInvoice && (
                        <SearchableSelectField
                            isMultiSelect
                            selectedOptions={customers.map((c) => ({
                                value: c.customerCode ?? '',
                                label: c.customerCompanyName ?? '',
                            }))}
                            setSelectedOptions={(options) => {
                                setValue(
                                    'customers',
                                    options.map((o) => ({
                                        customerCompanyName: o.label?.toString(),
                                        customerCode: o.value,
                                    })),
                                );
                            }}
                            placeholder="Cliente"
                            isLoading={isLoading}
                            options={options.customers}
                        />
                    )}
                    {!isCustomerInvoice && (
                        <SearchableSelectField
                            isMultiSelect
                            selectedOptions={suppliers.map((s) => ({
                                value: s.supplierCode ?? '',
                                label: s.supplierCompanyName ?? '',
                            }))}
                            setSelectedOptions={(options) => {
                                setValue(
                                    'suppliers',
                                    options.map((o) => ({
                                        supplierCompanyName: o.label?.toString(),
                                        supplierCode: o.value,
                                    })),
                                );
                            }}
                            placeholder="Fornitori"
                            isLoading={isLoading}
                            options={options.suppliers}
                        />
                    )}
                    <DateRangePicker
                        startDate={documentDateFrom}
                        endDate={documentDateTo}
                        handleSelect={(value) => {
                            setValue(
                                'documentDateFrom',
                                value?.selection.startDate?.toISOString() ?? '',
                            );
                            setValue(
                                'documentDateTo',
                                value?.selection.endDate?.toISOString() ?? '',
                            );
                        }}
                        label="Data documento"
                    />
                    <TextField
                        label="Doc. ref."
                        width={100}
                        onChange={(value) => setValue('documentNumber', value)}
                        value={documentNumber}
                    />
                    <PriceRange
                        label="Importo"
                        handleChange={(costFrom, costTo) => {
                            setValue('costFrom', costFrom);
                            setValue('costTo', costTo);
                        }}
                        max={costTo}
                        min={costFrom}
                    />
                    <DateRangePicker
                        startDate={dueDateFrom}
                        endDate={dueDateTo}
                        handleSelect={(value) => {
                            setValue(
                                'dueDateFrom',
                                value?.selection.startDate?.toISOString() ?? '',
                            );
                            setValue('dueDateTo', value?.selection.endDate?.toISOString() ?? '');
                        }}
                        label="Scadenza"
                    />
                    <SearchableSelectField
                        isMultiSelect
                        selectedOptions={clearDateStatuses}
                        setSelectedOptions={(options) => setValue('clearDateStatuses', options)}
                        placeholder="Stato pagamento"
                        isLoading={isLoading}
                        options={options.clearDateStatuses}
                    />
                    <SearchableSelectField
                        isMultiSelect
                        selectedOptions={sdiReplyStatuses.map((s) => ({
                            value: s.sdiReplyStatusCode ?? '',
                            label: s.sdiReplyStatusName ?? '',
                        }))}
                        setSelectedOptions={(options) => {
                            setValue(
                                'sdiReplyStatuses',
                                options.map((o) => ({
                                    sdiReplyStatusName: o.label?.toString(),
                                    sdiReplyStatusCode: o.value,
                                })),
                            );
                        }}
                        placeholder="Stato SDI"
                        isLoading={isLoading}
                        options={[
                            {
                                value: ' ',
                                label: 'NESSUNO STATO',
                            },
                            ...options.sdiReplyStatuses,
                        ]}
                    />
                    <SearchableSelectField
                        isMultiSelect
                        selectedOptions={ceilingStatuses.map((c) => ({
                            value: c.ceilingStatusCode ?? '',
                            label: c.ceilingStatusValue ?? '',
                        }))}
                        setSelectedOptions={(options) => {
                            setValue(
                                'ceilingStatuses',
                                options.map((o) => ({
                                    ceilingStatusValue: o.label?.toString(),
                                    ceilingStatusCode: o.value,
                                })),
                            );
                        }}
                        placeholder="Stato Plafond"
                        isLoading={isLoading}
                        options={options.ceilingStatuses}
                    />
                    <SearchableSelectField
                        isMultiSelect
                        selectedOptions={documentTypes.map((d) => ({
                            value: d.documentTypeCode ?? '',
                            label: d.documentTypeValue ?? '',
                        }))}
                        setSelectedOptions={(options) => {
                            setValue(
                                'documentTypes',
                                options.map((o) => ({
                                    documentTypeValue: o.label?.toString(),
                                    documentTypeCode: o.value,
                                })),
                            );
                        }}
                        placeholder="Tipo Documento"
                        isLoading={isLoading}
                        options={options.documentTypes}
                    />
                </div>
                <div className="flex flex-col ml-auto gap-y-4">
                    <div className="flex gap-x-2">
                        <Button type="primary" label="Search" onClick={handleSubmit(onSubmit)} />
                        <Button
                            disabled={searchParams.size === 0}
                            type="icon"
                            icon={<FilterIcon />}
                            onClick={onFilterClear}
                        />
                        <Button
                            type="icon"
                            onClick={handleSubmit(onExcelDownload)}
                            disabled={documents?.totalResult === 0 || isDocumentsLoading}
                            icon={<ExcelIcon className="w-[18px] h-[18px]" />}
                            className="w-12"
                        />
                    </div>
                    <SearchableSelectField
                        size="lg"
                        closeMenuOnSelect
                        selectedOptions={sortExpression}
                        setSelectedOptions={(options) => {
                            setValue('sortExpression', options);
                            handleSubmit(onSubmit)();
                        }}
                        placeholder="Ordina per"
                        isLoading={isLoading}
                        options={options.sortExpressions}
                    />
                </div>
            </div>
        </div>
    );
};

export default InvoicesFilter;
