import { useMsal } from '@azure/msal-react';
import Button from 'components/shared/button';
import Modal from 'components/shared/modal';
import TextField from 'components/shared/text-field';
import { getGraphAccessToken } from 'helpers/auth/graphAuthToken';
import { useModal } from 'hooks/useModal';
import React, { useState } from 'react';
import { sendEmail } from 'services/sendEmail';
import { toast } from 'sonner';

const EmailModal: React.FC = () => {
    const { isOpen, type, onClose, data } = useModal();
    const [recipientEmail, setRecipientEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { instance, accounts } = useMsal();
    const isModalOpen = isOpen && type == 'email';

    const onSubmit = async () => {
        const graphToken = await getGraphAccessToken(accounts, instance);
        try {
            setIsLoading(true);
            sendEmail({
                hasPdf: data.hasPdf,
                id: data.documentId,
                recipientEmail: recipientEmail,
                graphToken: graphToken,
            })
                .then(() => {
                    toast.success('Email sent!');
                    onClose();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Failed to send e-mail!');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } catch (err) {
            console.error('SERVERS_CREATE', err);
        }
    };

    return (
        <Modal isOpen={isModalOpen} onClose={onClose} style={{ width: 480 }}>
            <div className="flex flex-col gap-6">
                <div>
                    <h2 className="text-xl font-bold text-dark-gray_main mb-1">
                        Inserisci il tuo indirizzo e-mail
                    </h2>
                    <p className="text-xs text-lighter-gray">
                        Aggiungi il separatore &quot;;&quot; per più e-mail
                    </p>
                </div>
                <div>
                    <TextField
                        label="E-mail"
                        onChange={(value) => setRecipientEmail(value)}
                        value={recipientEmail}
                        width={'100%'}
                    />
                </div>
                {!data.hasPdf && (
                    <div>
                        <p className="text-xs text-lighter-gray">Documento pdf non disponibile</p>
                        <p className="text-xs text-lighter-gray">
                            All&quot;e-mail non verrà allegato nulla
                        </p>
                    </div>
                )}
                <div className="flex justify-end gap-4">
                    <Button
                        onClick={onClose}
                        type="outline"
                        label="Annulla"
                        className="w-fit"
                        isLoading={isLoading}
                    />
                    <Button
                        onClick={() => onSubmit()}
                        type="primary"
                        label="Invia"
                        className="w-fit"
                        isLoading={isLoading}
                        disabled={!recipientEmail}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default EmailModal;
