export const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';

export const PAGE_SIZE = 25;

export const ROUTES = {
    HOME: '/',
    CUSTOMER_INVOICES: '/customer-invoices',
    SUPPLIER_INVOICES: '/supplier-invoices',
    BUSINESS_PARTNER: '/business-partner',
};
