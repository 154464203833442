import Spinner from 'components/shared/spinner';
import { cn } from 'helpers/utils';
import React from 'react';

interface IButtonProps {
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    label?: string;
    icon?: React.ReactNode;
    type: 'outline' | 'primary' | 'icon';
    className?: string;
}

const Button = ({
    onClick,
    disabled,
    isLoading = false,
    label,
    type,
    icon,
    className,
}: IButtonProps) => {
    return (
        <button
            onClick={onClick}
            disabled={isLoading || disabled}
            className={cn(
                'flex items-center gap-x-1 text-sm px-6 py-2.5 rounded-lg disabled:bg-transparent duration-100 disabled:opacity-40',
                type === 'outline' && 'border border-lighter-gray-3 hover:bg-lighter-gray-6',
                type === 'primary' && 'bg-dark-gray_main text-white',
                type === 'icon' && 'border border-lighter-gray-6 hover:bg-lighter-gray-5 px-3',
                isLoading && 'cursor-wait',
                className,
            )}
            style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
        >
            <Spinner visible={isLoading} />
            {type === 'icon' ? icon : label}
        </button>
    );
};

export default Button;
