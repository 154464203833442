import EmailModal from 'components/modals/email';
import React from 'react';

const ModalProvider = () => {
    return (
        <>
            <EmailModal />
        </>
    );
};

export default ModalProvider;
