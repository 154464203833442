import axios from 'axios';
import { BASE_URL } from 'consts';
import { getAuthHead } from 'helpers/auth';

interface IParams {
    hasPdf: boolean | undefined;
    id: string | undefined;
    recipientEmail: string;
    graphToken: string;
}

export const sendEmail = async (data: IParams) => {
    const auth = await getAuthHead();
    return await axios.post(`${BASE_URL}SapPortal/sendEmail`, data, {
        headers: auth.headers,
    });
};
