import { PublicClientApplication } from '@azure/msal-browser';

import { loginRequest, msalConfig } from './config';

export const msalInstance = new PublicClientApplication(msalConfig);

export const getAuthHead = async () => {
    const accounts = msalInstance.getAllAccounts();

    const res = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
    });

    return {
        headers: { authorization: `Bearer ${res.accessToken}` },
    };
};

export function signOutClickHandler(instance: any, homeAccountId: string) {
    const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: '/',
    };
    instance.logoutRedirect(logoutRequest);
}
