import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import React, { useEffect, useState } from 'react';

const iconMap = {
    danger: <DangerIcon />,
    warning: <DangerIcon />,
    info: <DangerIcon />,
};

interface AlertComponentProps {
    content: React.ReactNode;
    type: 'danger' | 'warning' | 'info';
    backgroundColor: string;
    borderColor: string;
    padding: string;
}

const AlertComponent = ({
    content,
    type,
    backgroundColor,
    borderColor,
    padding,
}: AlertComponentProps) => {
    const [isOpen, setOpen] = useState(true);

    useEffect(() => {
        setOpen(true);
    }, [content]);

    if (!isOpen) {
        return null;
    }

    return (
        <div
            className="flex justify-between w-full items-center border rounded-lg"
            style={{ backgroundColor, borderColor, padding }}
        >
            <div className="flex items-center gap-x-3">
                {iconMap[type]}
                {content}
            </div>
            <CloseIcon onClick={() => setOpen(false)} className="cursor-pointer" />
        </div>
    );
};

export default AlertComponent;
