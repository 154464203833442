import InvoicesFilter from 'components/invoices/filter';
import InvoicesTable from 'components/invoices/table';
import moment from 'moment';
import React, { useRef } from 'react';
import { useGetSapPortalQuery, usePostSapPortalMutation } from 'store/rtk/allApis';

const CustomerInvoicesPage = () => {
    const { data: sapPortalData, isLoading: isSapPortalDataLoading } = useGetSapPortalQuery('');
    const [, { data, isSuccess, isLoading }] = usePostSapPortalMutation({
        fixedCacheKey: 'sap-portal-data',
    });
    const filterWrapperRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className="flex items-center justify-between pt-4 px-6 sticky left-0">
                <h1 className="text-[#0B121F] text-[28px] inline-block font-bold leading-9 pb-5 border-b-2 border-[#0B121F]  mb-[1.4px]">
                    Fatture clienti
                </h1>
                <div className="pb-2 flex flex-col gap-y-1">
                    <div className="font-bold text-dark-gray_main flex justify-end">
                        {isSuccess && <span>{`Elementi trovati: ${data?.totalResult ?? 0}`}</span>}
                        {isLoading && (
                            <div className="w-[180px] h-5 bg-lighter-gray-3/50 rounded-sm animate-pulse" />
                        )}
                    </div>
                    <div className="text-xs flex items-center">
                        {isSapPortalDataLoading && (
                            <div className="w-[300px] h-4 bg-lighter-gray-3/50 rounded-sm animate-pulse" />
                        )}
                        {sapPortalData && sapPortalData.lastInsertDate && (
                            <p>
                                Ultimo aggiornamento il{' '}
                                <span className="font-bold">
                                    {moment(sapPortalData.lastInsertDate).format(
                                        'DD MMMM YYYY, HH:mm',
                                    )}
                                </span>
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="sticky top-0 left-0 bg-white z-[60] px-6">
                <div className="border-b border-gray_200 mt-[-1.5px]" />
                <InvoicesFilter filterWrapperRef={filterWrapperRef} />
            </div>
            <div className="invoice-page text-black pb-4 font-thin m-auto w-full rounded-lg relative">
                <InvoicesTable filterWrapperRef={filterWrapperRef} />
            </div>
        </>
    );
};

export default CustomerInvoicesPage;
