import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import HomeStatus from 'components/home-status';
import Container from 'components/shared/container';
import Spinner from 'components/shared/spinner';
import React from 'react';
import { useGetSapPortalQuery } from 'store/rtk/allApis';

const HomePage = () => {
    const { data, isError, isLoading } = useGetSapPortalQuery('');

    if (isLoading) {
        return (
            <div className="mt-40 w-full h-full flex flex-col items-center justify-center gap-y-6">
                <Spinner width={40} height={40} />
                <p className="text-xl font-bold"> Loading...</p>
            </div>
        );
    }

    if (isError || !data) {
        return (
            <div className="mt-40 w-full h-full flex flex-col items-center justify-center">
                <DangerIcon className="w-10 h-10 mb-6 fill-rose-600" />
                <p className="text-xl font-bold text-rose-600">Something went wrong!</p>
            </div>
        );
    }

    return (
        <Container>
            <h1 className="text-[#0B121F] text-[28px] inline-block font-bold leading-9 pb-6 border-b-2 border-[#0B121F] mt-6">
                Home
            </h1>
            <div className="border-b border-gray_200 mt-[-1.5px]" />
            <p className="mt-10 text-xl font-semibold">Accesso rapido ai documenti in eccezione:</p>
            <div className="w-full h-[70vh] flex justify-center items-center gap-10 flex-wrap">
                <HomeStatus
                    label="rifiutate da SDI"
                    query={{
                        sdiReplyStatuses: data.sdiReplyStatusClient?.sdiReplyStatusesClient ?? [],
                    }}
                    errorCount={data.sdiReplyStatusClient?.totalSizeSdiReplyStatusClient ?? 0}
                />
                <HomeStatus
                    query={{ ceilingStatuses: data.statoPlafondClient?.statoPlafondsClient ?? [] }}
                    label="fuori plafond"
                    errorCount={data.statoPlafondClient?.totalSizeStatoPlafondClient ?? 0}
                />
            </div>
        </Container>
    );
};

export default HomePage;
