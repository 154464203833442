import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function formatPrice(price: number) {
    const formatter = new Intl.NumberFormat();
    return formatter.format(price);
}

export function clearObject(obj: any) {
    const clearedObject: any = {};
    Object.keys(obj).forEach((key) => {
        if (!obj[key]) return;
        if (Array.isArray(obj[key]) && obj[key].length === 0) return;
        clearedObject[key] = obj[key];
    });
    return clearedObject;
}

export function padWithLeadingZeros(num: number, totalLength: number) {
    return String(num).padStart(totalLength, '0');
}
