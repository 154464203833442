import { Popover } from '@headlessui/react';
import { ReactComponent as ClearIcon } from 'assets/icons/cancel.svg';
import { ReactComponent as EuroIcon } from 'assets/icons/euro.svg';
import { cn } from 'helpers/utils';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

interface PriceRangeProps {
    label: string;
    handleChange: (minValue: string, maxValue: string) => void;
    min: string;
    max: string;
}

const PriceRange = ({ label, handleChange, min, max }: PriceRangeProps) => {
    const [referenceElement, setReferenceElement] = useState<any>();
    const [popperElement, setPopperElement] = useState<any>();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });

    const fieldLabel = useMemo(() => {
        if (max && min) {
            return `€${min} ~ €${max}`;
        }
        if (max) return `to: €${max}`;
        if (min) return `from: €${min}`;
        return label;
    }, [label, min, max]);
    return (
        <div className="relative ">
            <Popover>
                {(state) => (
                    <>
                        <Popover.Button
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            ref={setReferenceElement}
                            className="border-none outline-none"
                        >
                            <div
                                className={cn(
                                    'w-[160px] h-[42px] border border-gray_300 py-[10px] rounded-lg pl-2 pr-1 flex items-center gap-x-1  cursor-pointer ',
                                    state.open
                                        ? 'border-[#1976d2] text-[#1976d2] stroke-[#1976d2]'
                                        : 'text-[#909090] stroke-[#344054] hover:border-[#000000de]',
                                )}
                                style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
                            >
                                <EuroIcon stroke="inherit" />
                                <p className="text-xs font-normal">{fieldLabel}</p>
                                {(min || max) && (
                                    <ClearIcon
                                        className="hover:opacity-70 ml-auto"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleChange('', '');
                                        }}
                                    />
                                )}
                            </div>
                        </Popover.Button>
                        {ReactDOM.createPortal(
                            <Popover.Panel
                                ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper}
                                className="z-[80]"
                            >
                                <div
                                    style={{
                                        boxShadow:
                                            '0px 8px 8px -4px rgba(16, 40, 27, 0.03), 0px 20px 24px -4px rgba(16, 40, 27, 0.08)',
                                    }}
                                    className={cn('w-[280px] bg-white z-20 pb-4')}
                                >
                                    <h4 className="text-dark-gray_main text-sm font-bold p-4 border-b border-lighter-gray-3">
                                        {label}
                                    </h4>
                                    <div className="flex gap-x-4 items-center px-4 py-2 w-full">
                                        <div className="flex flex-col gap-y-1.5 grow">
                                            <label htmlFor="min-price">Minimo</label>
                                            <div className="relative w-full text-[rgba(51,51,51,0.60)]">
                                                <input
                                                    type="number"
                                                    value={min || ''}
                                                    style={{
                                                        boxShadow:
                                                            '0px 1px 2px 0px rgba(16, 40, 27, 0.05)',
                                                    }}
                                                    className="w-full pr-2.5 py-3.5 pl-6 rounded-lg border border-foundation-neutral-light outline-none focus:border-dark-gray_main"
                                                    onChange={(e) =>
                                                        handleChange(e.target.value, max)
                                                    }
                                                />
                                                <EuroIcon className="absolute top-[15px] left-1" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-y-1.5 grow">
                                            <label htmlFor="min-price">Massimo</label>
                                            <div className="relative w-full text-[rgba(51,51,51,0.60)]">
                                                <input
                                                    type="number"
                                                    value={max}
                                                    style={{
                                                        boxShadow:
                                                            '0px 1px 2px 0px rgba(16, 40, 27, 0.05)',
                                                    }}
                                                    className="w-full pr-2.5 py-3.5 pl-6 rounded-lg border border-foundation-neutral-light outline-none focus:border-dark-gray_main"
                                                    onChange={(e) =>
                                                        handleChange(min, e.target.value)
                                                    }
                                                />
                                                <EuroIcon className="absolute top-[15px] left-1" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>,
                            document.body,
                        )}
                    </>
                )}
            </Popover>
        </div>
    );
};

export default PriceRange;
