import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import React from 'react';

const getBadge = (isCustomerInvoice: boolean) => ({
    Success: {
        bgColor: '#EBFBF3',
        textColor: '#09977B',
        text: 'Pareggiata',
        icon: <CheckIcon />,
    },
    Warning: {
        bgColor: '#FBF8EB',
        textColor: '#B86300',
        text: 'Non pareggiata',
        icon: <ClockIcon />,
    },
    Error: {
        bgColor: '#FEECEB',
        textColor: '#902922',
        text: isCustomerInvoice ? 'Scaduta' : 'Da pagare',
        icon: <WarningIcon />,
    },
});

interface TableStatusBadgeProps {
    isCustomerInvoice: boolean;
    type: 'Success' | 'Warning' | 'Error';
}

const TableStatusBadge = ({ type, isCustomerInvoice }: TableStatusBadgeProps) => {
    const badge = getBadge(isCustomerInvoice);

    return (
        <div
            className="px-4 py-1 gap-x-2 w-[169px] rounded-[46px] flex items-center"
            style={{
                backgroundColor: badge[type].bgColor,
                color: badge[type].textColor,
            }}
        >
            {badge[type].icon}
            <div>
                <h5 className="text-sm font-semibold">{badge[type].text}</h5>
            </div>
        </div>
    );
};

export default TableStatusBadge;
