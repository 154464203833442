import React from 'react';

interface ContainerProps {
    children: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
    return (
        <div
            style={{
                boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
            className="text-black pb-4  px-10 font-thin m-auto w-[calc(100%-72px)] md:w-[calc(100%-144px)] mt-6 rounded-lg relative border border-lighter-gray-5"
        >
            {children}
        </div>
    );
};

export default Container;
