import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

export const rtkBaseQueryFactory =
    ({
        getAxiosInstance,
    }: {
        getAxiosInstance: () => Promise<AxiosInstance>;
    }): BaseQueryFn<
        {
            url: string;
            method?: AxiosRequestConfig['method'];
            body?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
            options?: AxiosRequestConfig;
        },
        unknown,
        unknown
    > =>
    async ({ url, method, body, params, options }) => {
        try {
            const axiosInstance = await getAxiosInstance();
            const result = await axiosInstance({
                url: url,
                method: method ?? 'GET',
                data: body,
                params,
                ...options,
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };
