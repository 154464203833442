import { cn } from 'helpers/utils';
import React from 'react';

interface ISkeletonProps {
    className?: string;
}
const Skeleton = ({ className }: ISkeletonProps) => {
    return (
        <div className={cn('w-full bg-lighter-gray-3/50 rounded-md animate-pulse', className)} />
    );
};

export default Skeleton;
