import React from 'react';
import { DocumentItem } from 'store/rtk/allApis';

interface IBusinessPartnerDivisionTabProps {
    document: DocumentItem;
}
const BusinessPartnerDivisionTab = ({ document }: IBusinessPartnerDivisionTabProps) => {
    return (
        <div className="w-full overflow-auto border border-[#EAECF0] rounded-xl">
            <div className="grid grid-cols-divisione_table bg-gray_200 rounded-t-lg min-w-full w-fit text-[12px] text-gray_500">
                <p className="px-4 py-3">Divisione</p>
                <p className="px-4 py-3">Tipo</p>
                <p className="px-4 py-3">Codice FI</p>
                <p className="px-4 py-3">Codice ERP</p>
                <p className="px-4 py-3">Ref. Comm.</p>
                <p className="px-4 py-3">Ref. Amm.</p>
                <p className="px-4 py-3">Owner Campionario</p>
                <p className="px-4 py-3">Owner Produzione</p>
            </div>
            <div>
                {document.divisions?.map((division, index) => (
                    <div
                        key={index}
                        className="grid grid-cols-divisione_table items-center py-4 min-w-full w-fit text-sm text-gray_500 border-b border-gray_200 last:border-none"
                    >
                        <p className="px-4 font-medium text-gray_900">
                            {division.division} - {division.dDivision}
                        </p>
                        <p className="px-4 flex flex-col gap-y-1">
                            {division.oldNums?.map((item, idx) => (
                                <span key={idx}>{item.tipo}</span>
                            ))}
                        </p>
                        <p className="px-4 flex flex-col gap-y-1">
                            {division.oldNums?.map((item, idx) => (
                                <span key={idx}>{item.fiCode}</span>
                            ))}
                        </p>
                        <p className="px-4 flex flex-col gap-y-1">
                            {division.oldNums?.map((item, idx) => (
                                <span key={idx}>{item.erpCode}</span>
                            ))}
                        </p>
                        <p className="px-4 flex flex-col gap-y-1 break-words">
                            {division.mails?.refComm || '-'}
                        </p>
                        <p className="px-4 flex flex-col gap-y-1 break-words">
                            {division.mails?.refAmm || '-'}
                        </p>
                        <p className="px-4 flex flex-col gap-y-1 break-words">
                            {division.mails?.ownerCamp || '-'}
                        </p>
                        <p className="px-4 flex flex-col gap-y-1 break-words">
                            {division.mails?.ownerProd || '-'}
                        </p>
                    </div>
                ))}
                {document.divisions?.length === 0 && (
                    <p className="text-center py-4 text-sm text-gray_500">
                        Nessuna divisione trovata
                    </p>
                )}
            </div>
        </div>
    );
};

export default BusinessPartnerDivisionTab;
