import 'styles/index.css';
import 'styles/custom.css';
import 'moment/locale/it';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ModalProvider from 'components/providers/modal-provider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';
import { Toaster } from 'sonner';

import { msalConfig } from './helpers/auth/config';
import { store } from './store/store';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
        <Toaster expand={true} richColors />
        <ModalProvider />
    </MsalProvider>,
    // </React.StrictMode>,
);
