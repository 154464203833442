import { useMsal } from '@azure/msal-react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as LogOutIcon } from 'assets/icons/log-out.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import logoImg from 'assets/images/logo.png';
import { ROUTES } from 'consts';
import { signOutClickHandler } from 'helpers/auth';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const { accounts, instance } = useMsal();

    return (
        <div className="bg-[#3A3836] px-6 py-4 text-white flex items-center justify-between sticky left-0 z-50">
            <div className="flex items-center gap-x-10">
                <Link to="/" className="flex gap-x-2 items-center ">
                    <img src={logoImg} alt="Logo" className="w-8 h-8 object-contain" />
                    <h1 className="uppercase text-base tracking-[0.48px]">
                        Finance & Treasury Portal
                    </h1>
                </Link>
                <div>
                    <NavLink
                        to="/"
                        className="px-3 py-2 rounded-md duration-75 hover:text-[#dcdcdc]"
                        style={(state) => ({
                            backgroundColor: state.isActive ? '#ffffff0d' : 'transparent',
                        })}
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to={ROUTES.CUSTOMER_INVOICES}
                        className="px-3 py-2 rounded-md duration-75 hover:text-[#dcdcdc] "
                        style={(state) => ({
                            backgroundColor: state.isActive ? '#ffffff0d' : 'transparent',
                        })}
                    >
                        Fatture Clienti
                    </NavLink>
                    <NavLink
                        to={ROUTES.SUPPLIER_INVOICES}
                        className="px-3 py-2 rounded-md duration-75 hover:text-[#dcdcdc]"
                        style={(state) => ({
                            backgroundColor: state.isActive ? '#ffffff0d' : 'transparent',
                        })}
                    >
                        Fatture Fornitori
                    </NavLink>
                    <NavLink
                        to={ROUTES.BUSINESS_PARTNER}
                        className="px-3 py-2 rounded-md duration-75 hover:text-[#dcdcdc]"
                        style={(state) => ({
                            backgroundColor: state.isActive ? '#ffffff0d' : 'transparent',
                        })}
                    >
                        Business Partner
                    </NavLink>
                    <Link
                        to={
                            'https://gruppoflorence.sharepoint.com/sites/PortaleFT/Documenti%20condivisi/Forms/AllItems.aspx'
                        }
                        target="_blank"
                        className="px-3 py-2 rounded-md duration-75 hover:text-[#dcdcdc]"
                    >
                        Documenti
                    </Link>
                </div>
            </div>
            <div className="flex items-center">
                <Menu as="div" className="ml-auto relative inline-block text-left z-[65]">
                    <div>
                        <Menu.Button className="flex items-center gap-x-4 font-semibold">
                            {accounts && accounts[0] && (
                                <h3 className="translate-y-[1px] hidden md:inline-block">
                                    {accounts[0].name || ''}
                                </h3>
                            )}
                            <ProfileIcon className="cursor-pointer" />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 mt-2 w-28 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 rounded-md bg-[#3A3836]">
                                <Menu.Item>
                                    {() => (
                                        <button
                                            onClick={() =>
                                                signOutClickHandler(
                                                    instance,
                                                    accounts[0]?.homeAccountId,
                                                )
                                            }
                                            className="group gap-x-2 flex w-full items-center rounded-md px-2 py-2 text-sm font-semibold hover:bg-gray-100 hover:text-black"
                                        >
                                            <LogOutIcon className="w-4" />
                                            Log Out
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    );
};

export default Header;
