import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-clear.svg';
import Button from 'components/shared/button';
import CheckBox from 'components/shared/checkbox';
import SearchableSelectField from 'components/shared/searchable-select';
import TextField from 'components/shared/text-field';
import { clearObject } from 'helpers/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { allApis } from 'store/rtk';
import {
    Division,
    usePostSapPortalBusinessPartnerMutation,
    usePostSapPortalExcelExportBpMutation,
} from 'store/rtk/allApis';
import { ISelectOption } from 'types';

const formDefaultValues = {
    searchKey: '',
    divisions: [],
    erpCode: '',
    fiCode: '',
    taxNumAndNumXl: '',
    sortExpression: [],
    typology: [],
    allBPFromSAP: false,
};
type SearchFilterType = {
    searchKey: string;
    erpCode: string;
    fiCode: string;
    taxNumAndNumXl: string;
    allBPFromSAP: boolean;
    divisions: Division[];
    typology: ISelectOption[];
    sortExpression: ISelectOption[];
};
interface BusinessPartnerFilterProps {
    filterWrapperRef: React.RefObject<HTMLDivElement>;
}

const BusinessPartnerFilter = ({ filterWrapperRef }: BusinessPartnerFilterProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [, { data: documents, isLoading: isDocumentsLoading }] =
        usePostSapPortalBusinessPartnerMutation({
            fixedCacheKey: 'business-partner-data',
        });
    const [executeExcelExport] = usePostSapPortalExcelExportBpMutation();
    const { isLoading, data, isSuccess } = allApis.useGetSapPortalSearchBarQuery(
        {
            invoiceType: 'C',
        },
        { refetchOnMountOrArgChange: true },
    );

    const { handleSubmit, watch, setValue, reset } = useForm<SearchFilterType>({
        defaultValues: formDefaultValues,
    });

    const options = useMemo(() => {
        return {
            divisions:
                data?.divisions
                    ?.map((d) => ({
                        value: d.divisionCode ?? '',
                        label: d.companyDivisionName ?? '',
                    }))
                    .filter((d) => d.value && d.label)
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? [],

            sortExpressions: [
                {
                    value: 'name-asc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowUpIcon /> Nome
                        </div>
                    ),
                },
                {
                    value: 'name-desc',
                    label: (
                        <div className="flex items-center gap-x-3">
                            <ArrowDownIcon />
                            Nome
                        </div>
                    ),
                },
            ],
            typologies: [
                {
                    value: '0',
                    label: 'Fornitore',
                },
                {
                    value: '1',
                    label: 'Cliente',
                },
            ],
        };
    }, [data]);

    const taxNumAndNumXl = watch('taxNumAndNumXl');
    const sortExpression = watch('sortExpression');
    const typology = watch('typology');
    const divisions = watch('divisions');
    const searchKey = watch('searchKey');
    const erpCode = watch('erpCode');
    const fiCode = watch('fiCode');
    const allBPFromSAP = watch('allBPFromSAP');

    const fillSearchFields = useCallback(() => {
        const searchKey = searchParams.get('searchKey') ?? '';
        const taxNumAndNumXl = searchParams.get('taxNumAndNumXl') ?? '';
        const erpCode = searchParams.get('erpCode') ?? '';
        const fiCode = searchParams.get('fiCode') ?? '';
        const divisions = searchParams.getAll('divisions');
        const sortExpression = searchParams.get('sortExpression');
        const typology = searchParams.get('typology');
        const allBPFromSAP = searchParams.get('allBPFromSAP');

        reset({
            searchKey,
            taxNumAndNumXl,
            erpCode,
            fiCode,
            divisions: options.divisions
                .filter((option) => divisions.includes(option.value))
                .map((option) => ({
                    companyDivisionName: option.label ?? '',
                    divisionCode: option.value,
                })),
            sortExpression: options.sortExpressions.filter(
                (s: ISelectOption) => s.value === sortExpression,
            ),
            typology: options.typologies.filter((s: ISelectOption) => s.value === typology),
            allBPFromSAP: allBPFromSAP === 'true',
        });
    }, [searchParams, reset, options.sortExpressions, options.divisions]);

    useEffect(() => {
        if (isSuccess && data) {
            fillSearchFields();
        }
    }, [data, isSuccess]);

    const onSubmit = useCallback(
        (data: SearchFilterType) => {
            const clearedObj = clearObject(data);
            const query: any = {};

            if (clearedObj.searchKey) {
                query.searchKey = clearedObj.searchKey;
            }
            if (clearedObj.taxNumAndNumXl) {
                query.taxNumAndNumXl = clearedObj.taxNumAndNumXl;
            }
            if (clearedObj.erpCode) {
                query.erpCode = clearedObj.erpCode;
            }
            if (clearedObj.fiCode) {
                query.fiCode = clearedObj.fiCode;
            }
            if (clearedObj.divisions?.length) {
                query.divisions = clearedObj.divisions.map((d: Division) => d.divisionCode);
            }
            if (clearedObj.sortExpression?.length) {
                query.sortExpression = clearedObj.sortExpression[0].value;
            }
            if (clearedObj.typology?.length) {
                query.typology = clearedObj.typology[0].value;
            }
            if (clearedObj.allBPFromSAP) {
                query.allBPFromSAP = clearedObj.allBPFromSAP;
            }

            setSearchParams(createSearchParams(query));
        },
        [searchParams],
    );
    const onFilterClear = useCallback(() => {
        reset(formDefaultValues);
        setSearchParams({});
    }, [setSearchParams, reset]);

    useEffect(() => {
        const onSearchKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                handleSubmit(onSubmit)();
            }
        };
        document.addEventListener('keydown', onSearchKeyPress);
        return () => document.removeEventListener('keydown', onSearchKeyPress);
    }, []);

    const onExcelDownload = useCallback(
        (data: SearchFilterType) => {
            const clearedObj = clearObject(data);
            const query: any = {};

            if (documents?.totalResult && documents.totalResult > 10000) {
                query.size = documents?.totalResult;
            }
            if (clearedObj.searchKey) {
                query.searchKey = clearedObj.searchKey;
            }
            if (clearedObj.taxNumAndNumXl) {
                query.taxNumAndNumXl = clearedObj.taxNumAndNumXl;
            }
            if (clearedObj.erpCode) {
                query.erpCode = clearedObj.erpCode;
            }
            if (clearedObj.fiCode) {
                query.fiCode = clearedObj.fiCode;
            }
            if (clearedObj.divisions?.length) {
                query.divisions = clearedObj.divisions.map((d: Division) => d.divisionCode);
            }
            if (clearedObj.typology?.length) {
                query.typology = clearedObj.typology[0].value;
            }
            if (clearedObj.allBPFromSAP) {
                query.allBPFromSAP = clearedObj.allBPFromSAP;
            }
            if (clearedObj.sortExpression?.length) {
                const [sortField, sortDirection] = clearedObj.sortExpression[0].value.split('-');
                query.sortExpression = {
                    [sortField]: sortDirection,
                };
            }

            const executeExportPromise = executeExcelExport(query)
                .unwrap()
                .then((data) => {
                    const base64String = data.fileNameBase64 ?? '';
                    const fileName = data.fileName ?? '';

                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    const blob = new Blob([byteArray], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });

                    const url = URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    URL.revokeObjectURL(url);
                });

            toast.promise(executeExportPromise, {
                loading: 'Download in corso...',
                success: 'Download completato',
                error: 'Errore durante il download',
            });
        },
        [documents?.totalResult, executeExcelExport],
    );

    return (
        <div
            ref={filterWrapperRef}
            className="text-[#171717b3] text-sm w-full my-4 pb-4 flex items-start justify-between gap-x-6 border-b border-gray-200 "
        >
            <div className="flex flex-wrap items-center gap-2.5">
                <TextField
                    label="Full search"
                    width={280}
                    onChange={(value) => setValue('searchKey', value)}
                    value={searchKey}
                />
                <TextField
                    label="P.IVA/VAT CODE"
                    width={150}
                    onChange={(value) => setValue('taxNumAndNumXl', value)}
                    value={taxNumAndNumXl}
                />
                <TextField
                    label="Codice ERP"
                    description="Codice identificativo utilizzato nel proprio sistema gestionale"
                    width={140}
                    onChange={(value) => setValue('erpCode', value)}
                    value={erpCode}
                />
                <TextField
                    label="Codice FI"
                    description="Codice identificativo utilizzato nel proprio sistema gestionale Finance"
                    width={140}
                    onChange={(value) => setValue('fiCode', value)}
                    value={fiCode}
                />
                <SearchableSelectField
                    isMultiSelect
                    selectedOptions={divisions.map((d) => ({
                        value: d.divisionCode ?? '',
                        label: d.companyDivisionName ?? '',
                    }))}
                    setSelectedOptions={(options) => {
                        setValue(
                            'divisions',
                            options.map((o) => ({
                                companyDivisionName: o.label?.toString(),
                                divisionCode: o.value,
                            })),
                        );
                    }}
                    placeholder="Divisione"
                    isLoading={isLoading}
                    options={options.divisions}
                />
                <SearchableSelectField
                    size="md"
                    closeMenuOnSelect
                    selectedOptions={typology}
                    setSelectedOptions={(options) => {
                        setValue('typology', options);
                    }}
                    placeholder="Tipologia"
                    isLoading={isLoading}
                    options={options.typologies}
                />
                <CheckBox
                    label={<span className="text-sm">All BP from SAP</span>}
                    checked={allBPFromSAP}
                    onChange={(value) => setValue('allBPFromSAP', value)}
                />
            </div>
            <div className="flex flex-col ml-auto gap-y-4">
                <div className="flex gap-x-2">
                    <Button
                        type="primary"
                        label="Search"
                        onClick={handleSubmit(onSubmit)}
                        className="w-full justify-center"
                    />
                    <Button
                        disabled={searchParams.size === 0}
                        type="icon"
                        icon={<FilterIcon />}
                        onClick={onFilterClear}
                    />
                    <Button
                        type="icon"
                        onClick={handleSubmit(onExcelDownload)}
                        disabled={documents?.totalResult === 0 || isDocumentsLoading}
                        icon={<ExcelIcon className="w-[18px] h-[18px]" />}
                        className="w-12"
                    />
                </div>
                <SearchableSelectField
                    size="lg"
                    closeMenuOnSelect
                    selectedOptions={sortExpression}
                    setSelectedOptions={(options) => {
                        setValue('sortExpression', options);
                        handleSubmit(onSubmit)();
                    }}
                    placeholder="Ordina per"
                    isLoading={isLoading}
                    options={options.sortExpressions}
                />
            </div>
        </div>
    );
};

export default BusinessPartnerFilter;
