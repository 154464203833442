import React, { useEffect, useState } from 'react';

const getHeadingColumns = (invoiceType: 'C' | 'S') => [
    '',
    '',
    'Divisione\nTipo Documento\nData',
    invoiceType === 'C' ? 'Cliente\nNumero Doc' : 'Fornitori\nNumero Doc',
    'Doc ref.\nNumero Doc. SAP',
    'Importo',
    'Storno',
    'Stato pagamento',
    'Scadenza',
    'IBAN',
    'SDI',
    'Plafond',
    'Stato Elab. Fattura',
    'Stato SDI',
    'Codice Scarto SDI',
];

interface TableHeaderProps {
    invoiceType: 'C' | 'S';
    filterWrapperRef: React.RefObject<HTMLDivElement>;
}

const TableHeader = ({ invoiceType, filterWrapperRef }: TableHeaderProps) => {
    const [stickyTop, setStickyTop] = useState(190);

    useEffect(() => {
        if (!filterWrapperRef.current) return;
        const onResize = () => {
            const height = +(filterWrapperRef.current?.getBoundingClientRect().height ?? 0);
            setStickyTop(height);
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);

    return (
        <div
            style={{
                top: stickyTop,
            }}
            className="px-6 py-2 grid grid-cols-invoice_table gap-x-4 bg-lighter-gray-6 rounded-t-lg min-w-full w-fit sticky z-10"
        >
            {getHeadingColumns(invoiceType).map((col) => (
                <p key={col} className="text-[11px] text-lighter-gray flex flex-col justify-center">
                    {col.split('\n').map((str, idx) => (
                        <span key={idx}>{str}</span>
                    ))}
                </p>
            ))}
        </div>
    );
};

export default TableHeader;
