import axios from 'axios';
import { BASE_URL } from 'consts';
import { getAuthHead } from 'helpers/auth';

interface IParams {
    fileType: 'xml' | 'pdfFromSAP' | 'pdfFromXML' | 'rxml';
    id: string;
}

export const getSapPortalFile = async (data: IParams) => {
    const auth = await getAuthHead();
    return await axios.post(`${BASE_URL}SapPortal/download`, data, {
        headers: auth.headers,
        responseType: 'blob',
    });
};
