import AlertComponent from 'components/shared/alert';
import Button from 'components/shared/button';
import { PAGE_SIZE, ROUTES } from 'consts';
import { clearObject } from 'helpers/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Document, DocumentSp, usePostSapPortalMutation } from 'store/rtk/allApis';

import Skeleton from '../../shared/skeleton';
import TableBody from './body';
import TableHeader from './header';

interface InvoicesTableProps {
    filterWrapperRef: React.RefObject<HTMLDivElement>;
}

const InvoicesTable = ({ filterWrapperRef }: InvoicesTableProps) => {
    const { pathname } = useLocation();
    const invoiceType = pathname === ROUTES.CUSTOMER_INVOICES ? 'C' : 'S';
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [executeGetPortalData, { data, isLoading, isSuccess, isError }] =
        usePostSapPortalMutation({ fixedCacheKey: 'sap-portal-data' });

    const [searchParams] = useSearchParams();

    const params: DocumentSp = useMemo(() => {
        const obj = clearObject({
            size: PAGE_SIZE,
            invoiceType,
            divisions: searchParams.getAll('divisions'),
            customers: searchParams.getAll('customers'),
            suppliers: searchParams.getAll('suppliers'),
            sdiReplyStatuses: searchParams.getAll('sdiReplyStatuses'),
            clearDateStatuses: searchParams.getAll('clearDateStatuses'),
            documentTypes: searchParams.getAll('documentTypes'),
            ceilingStatuses: searchParams.getAll('ceilingStatuses'),
            documentNumber: searchParams.get('documentNumber'),
            documentNumberSAP: searchParams.get('documentNumberSAP'),
            documentDateFrom: searchParams.get('documentDateFrom'),
            documentDateTo: searchParams.get('documentDateTo'),
            dueDateFrom: searchParams.get('dueDateFrom'),
            dueDateTo: searchParams.get('dueDateTo'),
            searchKey: searchParams.get('searchKey'),
            companyCode: searchParams.get('companyCode'),
            sortExpression: searchParams.get('sortExpression'),
            costTo: parseInt(searchParams.get('costTo') ?? ''),
            costFrom: parseInt(searchParams.get('costFrom') ?? ''),
        });
        if (obj.sortExpression) {
            const [sortField, sortDirection] = obj.sortExpression.split('-');
            obj.sortExpression = {
                [sortField]: sortDirection,
            };
        }
        return obj;
    }, [searchParams, invoiceType]);

    useEffect(() => {
        setDocuments([]);
        executeGetPortalData({ ...params });
    }, [params]);

    useEffect(() => {
        if (!isSuccess || !data || !data.documentList || isLoading) return;
        setDocuments(data.documentList);
        setIsMoreLoading(false);
    }, [data]);

    const getMoreDocuments = useCallback(() => {
        setIsMoreLoading(true);
        const size = documents.length + PAGE_SIZE;
        executeGetPortalData({ ...params, size });
    }, [params, executeGetPortalData, documents]);

    const errorCount = useMemo(
        () =>
            documents.reduce(
                (prev, val) =>
                    prev +
                    (val.unitAmount?.filter((a) => a.clearDateStatus === 'Error').length ?? 0),
                0,
            ),
        [documents],
    );

    return (
        <>
            <div className="px-6 mt-2">
                {data && errorCount > 0 && (
                    <AlertComponent
                        type="danger"
                        backgroundColor="#FFFBFA"
                        borderColor="#FDA29B"
                        padding="16px"
                        content={
                            <p className="text-[#B42318] text-sm">
                                <span className="font-bold">{`${errorCount} fatture `}</span> con
                                anomalie
                            </p>
                        }
                    />
                )}
                {isLoading && !isMoreLoading && <Skeleton className="h-[60px]" />}
            </div>
            <div className="mt-2 px-6">
                <div className="border-[0.5px] border-[#E6E6E8] mt-4 rounded-lg  pb-8 min-w-fit">
                    <TableHeader filterWrapperRef={filterWrapperRef} invoiceType={invoiceType} />
                    {isError && (
                        <div className="py-20 text-2xl font-semibold w-full">
                            <div className="absolute left-1/2 -translate-x-1/2">
                                <p className="text-rose-600 ">Something went wrong!</p>
                            </div>
                        </div>
                    )}
                    {isSuccess &&
                        data &&
                        data.documentList &&
                        data.documentList?.length === 0 &&
                        documents.length === 0 && (
                            <div className="py-20 text-2xl font-semibold w-full">
                                <div className="absolute left-1/2 -translate-x-1/2">
                                    <p className="text-yellow-500">Fatture non trovate!</p>
                                </div>
                            </div>
                        )}
                    {isLoading && !isMoreLoading && (
                        <div className="sticky left-0 grid place-items-center">
                            <div className="flex flex-col gap-y-6 px-6 py-3 w-full">
                                {new Array(8).fill(0).map((_, idx) => (
                                    <Skeleton key={idx} className="h-16" />
                                ))}
                            </div>
                        </div>
                    )}
                    {!isError && <TableBody documents={documents} />}
                    {documents.length !== 0 &&
                        documents.length % +(params.size ?? PAGE_SIZE) === 0 &&
                        !isError && (
                            <div className="sticky left-0 grid place-items-center">
                                <Button
                                    type="outline"
                                    onClick={getMoreDocuments}
                                    isLoading={isMoreLoading}
                                    label="Mostra di più"
                                    className=""
                                />
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};

export default InvoicesTable;
