import { Popover } from '@headlessui/react';
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as VisualizeIcon } from 'assets/icons/hide.svg';
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as XMLIcon } from 'assets/icons/xml.svg';
import { cn } from 'helpers/utils';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { getSapPortalFile } from 'services/getSapPortalFile';
import { toast } from 'sonner';

interface IActionsProps {
    documentId: string;
    isCustomerInvoice: boolean;
    hasPdf: boolean;
    hasXml: boolean;
    hasRXml: boolean;
}

const Actions = ({ documentId, hasPdf, hasXml, hasRXml, isCustomerInvoice }: IActionsProps) => {
    const { onOpen } = useModal();
    const [referenceElement, setReferenceElement] = useState<any>();
    const [popperElement, setPopperElement] = useState<any>();
    const [downloadingFile, setDownloadingFile] = useState<
        'xml' | 'pdfFromSAP' | 'pdfFromXML' | 'rxml' | null
    >(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });

    const onCreate = useCallback(
        (fileType: 'pdfFromSAP' | 'pdfFromXML' | 'xml' | 'rxml', type: 'open' | 'download') => {
            setDownloadingFile(fileType);
            getSapPortalFile({ fileType, id: documentId })
                .then((response) => {
                    let url;
                    let extension: string = fileType;
                    if (fileType === 'xml' || fileType === 'rxml') {
                        url = URL.createObjectURL(
                            new Blob([response.data], {
                                type: 'text',
                            }),
                        );
                    } else {
                        url = URL.createObjectURL(response.data);
                        extension = 'pdf';
                    }

                    if (type === 'open') {
                        window.open(url);
                        return;
                    } else {
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `${documentId}.${extension}`;
                        a.click();
                        a.remove();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('File not found! Something went wrong.');
                })
                .finally(() => setDownloadingFile(null));
        },
        [documentId],
    );

    return (
        <Popover>
            {() => (
                <>
                    <Popover.Button ref={setReferenceElement} className="outline-none">
                        <div className="w-9 h-9 hover:bg-lighter-gray-6 duration-100 rounded-full flex items-center justify-center bg-white shadow">
                            <DotsIcon className="cursor-pointer" />
                        </div>
                    </Popover.Button>
                    {ReactDOM.createPortal(
                        <Popover.Panel
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className="z-40"
                        >
                            <div
                                style={{
                                    boxShadow:
                                        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
                                }}
                                className="rounded-lg border border-gray-100 w-[250px] bg-white"
                            >
                                <div
                                    className={cn(
                                        'w-full px-4 py-1.5 flex justify-between items-center text-sm font-medium text-dark-gray_main uppercase border-b border-lighter-gray-5 duration-100',
                                        (!!downloadingFile || !hasPdf) && 'opacity-50',
                                    )}
                                >
                                    <div className="flex items-center gap-x-3">
                                        <PDFIcon className="w-4 h-4 object-contain" />
                                        <span>{isCustomerInvoice ? 'PDF From SAP' : 'PDF'}</span>
                                    </div>
                                    <div className="flex items-center gap-x-3">
                                        <button
                                            disabled={!!downloadingFile || !hasPdf}
                                            onClick={() => onCreate('pdfFromSAP', 'download')}
                                            className={cn(
                                                'bg-lighter-gray-6 hover:bg-lighter-gray-5 disabled:hover:bg-lighter-gray-6 p-2 rounded duration-100',
                                                !!downloadingFile && 'cursor-wait',
                                            )}
                                        >
                                            <DownloadIcon className="w-3.5 h-3.5 object-contain" />
                                        </button>
                                        <button
                                            disabled={!!downloadingFile || !hasPdf}
                                            onClick={() => onCreate('pdfFromSAP', 'open')}
                                            className={cn(
                                                'bg-lighter-gray-6 hover:bg-lighter-gray-5 disabled:hover:bg-lighter-gray-6 p-2 rounded duration-100',
                                                !!downloadingFile && 'cursor-wait',
                                            )}
                                        >
                                            <VisualizeIcon className="w-4 h-4 object-contain" />
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className={cn(
                                        'w-full px-4 py-1.5 flex justify-between items-center text-sm font-medium text-dark-gray_main uppercase border-b border-lighter-gray-5 duration-100',
                                        (!!downloadingFile || !hasXml) && 'opacity-50',
                                    )}
                                >
                                    <div className="flex items-center gap-x-3">
                                        <XMLIcon className="w-4 h-4 object-contain" />
                                        <span>XML</span>
                                    </div>
                                    <div className="flex items-center gap-x-3">
                                        <button
                                            disabled={!!downloadingFile || !hasXml}
                                            onClick={() => onCreate('xml', 'download')}
                                            className={cn(
                                                'bg-lighter-gray-6 hover:bg-lighter-gray-5 disabled:hover:bg-lighter-gray-6 p-2 rounded duration-100',
                                                !!downloadingFile && 'cursor-wait',
                                            )}
                                        >
                                            <DownloadIcon className="w-3.5 h-3.5 object-contain" />
                                        </button>
                                        <button
                                            disabled={!!downloadingFile || !hasXml}
                                            onClick={() => onCreate('xml', 'open')}
                                            className={cn(
                                                'bg-lighter-gray-6 hover:bg-lighter-gray-5 disabled:hover:bg-lighter-gray-6 p-2 rounded duration-100',
                                                !!downloadingFile && 'cursor-wait',
                                            )}
                                        >
                                            <VisualizeIcon className="w-4 h-4 object-contain" />
                                        </button>
                                    </div>
                                </div>
                                {isCustomerInvoice && (
                                    <div
                                        className={cn(
                                            'w-full px-4 py-1.5 flex justify-between items-center text-sm font-medium text-dark-gray_main uppercase border-b border-lighter-gray-5 duration-100',
                                            (!!downloadingFile || !hasXml) && 'opacity-50',
                                        )}
                                    >
                                        <div className="flex items-center gap-x-3">
                                            <PDFIcon className="w-4 h-4 object-contain" />
                                            <span>PDF FROM XML</span>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <button
                                                disabled={!!downloadingFile || !hasXml}
                                                onClick={() => onCreate('pdfFromXML', 'download')}
                                                className={cn(
                                                    'bg-lighter-gray-6 hover:bg-lighter-gray-5 disabled:hover:bg-lighter-gray-6 p-2 rounded duration-100',
                                                    !!downloadingFile && 'cursor-wait',
                                                )}
                                            >
                                                <DownloadIcon className="w-3.5 h-3.5 object-contain" />
                                            </button>
                                            <button
                                                disabled={!!downloadingFile || !hasXml}
                                                onClick={() => onCreate('pdfFromXML', 'open')}
                                                className={cn(
                                                    'bg-lighter-gray-6 hover:bg-lighter-gray-5 disabled:hover:bg-lighter-gray-6 p-2 rounded duration-100',
                                                    !!downloadingFile && 'cursor-wait',
                                                )}
                                            >
                                                <VisualizeIcon className="w-4 h-4 object-contain" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={cn(
                                        'w-full px-4 py-1.5 flex justify-between items-center text-sm font-medium text-dark-gray_main uppercase border-b border-lighter-gray-5 duration-100',
                                        (!!downloadingFile || !hasRXml) && 'opacity-50',
                                    )}
                                >
                                    <div className="flex items-center gap-x-3">
                                        <XMLIcon className="w-4 h-4 object-contain" />
                                        <span>Ricevuta XML</span>
                                    </div>
                                    <div className="flex items-center gap-x-3">
                                        <button
                                            disabled={!!downloadingFile || !hasRXml}
                                            onClick={() => onCreate('rxml', 'download')}
                                            className={cn(
                                                'bg-lighter-gray-6 hover:bg-lighter-gray-5 disabled:hover:bg-lighter-gray-6 p-2 rounded duration-100',
                                                !!downloadingFile && 'cursor-wait',
                                            )}
                                        >
                                            <DownloadIcon className="w-3.5 h-3.5 object-contain" />
                                        </button>
                                        <button
                                            disabled={!!downloadingFile || !hasRXml}
                                            onClick={() => onCreate('rxml', 'open')}
                                            className={cn(
                                                'bg-lighter-gray-6 hover:bg-lighter-gray-5 disabled:hover:bg-lighter-gray-6 p-2 rounded duration-100',
                                                !!downloadingFile && 'cursor-wait',
                                            )}
                                        >
                                            <VisualizeIcon className="w-4 h-4 object-contain" />
                                        </button>
                                    </div>
                                </div>

                                <button
                                    onClick={() => onOpen('email', { documentId, hasPdf })}
                                    className={cn(
                                        'w-full px-4 py-2.5 flex gap-x-3 items-center text-sm font-medium text-dark-gray_main uppercase hover:bg-lighter-gray-6 duration-100',
                                    )}
                                >
                                    <AlertIcon className="w-4 h-4 object-contain" />
                                    <span>Invia e-mail</span>
                                </button>
                            </div>
                        </Popover.Panel>,
                        document.body,
                    )}
                </>
            )}
        </Popover>
    );
};

export default Actions;
