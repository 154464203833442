import { PublicClientApplication } from '@azure/msal-browser';
import { Client, ClientOptions } from '@microsoft/microsoft-graph-client';
import { Group } from '@microsoft/microsoft-graph-types';

import { msalConfig } from './config';
import { GraphAuthProvider } from './graphAuthProvider';

export const instance = new PublicClientApplication(msalConfig);

export const getGroupAuth = async () => {
    const accounts = instance.getAllAccounts();
    let authGroups = '';
    if (accounts.length > 0) {
        const clientOptions: ClientOptions = {
            authProvider: new GraphAuthProvider(instance, accounts),
        };
        const client = Client.initWithMiddleware(clientOptions);
        await client
            .api('me/getMemberGroups')
            .post({ securityEnabledOnly: true })
            .then(async function (response) {
                const directoryObject = {
                    ids: response.value,
                    types: ['group'],
                };
                await client
                    .api('/directoryObjects/getByIds')
                    .post(directoryObject)
                    .then(async function (response) {
                        authGroups = (response.value as Group[])
                            .filter((group) => group.displayName?.startsWith('PortaleFT'))
                            .map((group) => group.displayName)
                            .toString();
                    });
            });
    }

    return authGroups;
};
