import React from 'react';
import { DocumentItem } from 'store/rtk/allApis';

interface IBusinessPartnerCustomerTabProps {
    document: DocumentItem;
}
const BusinessPartnerCustomerTab = ({ document }: IBusinessPartnerCustomerTabProps) => {
    return (
        <div className="grid grid-cols-4 gap-x-4 gap-y-10 text-sm">
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">Codice Cliente SAP</h5>
                <p className="text-[#93918F] font-thin">{document.client || '-'}</p>
            </div>
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">Società Cliente</h5>
                <p className="text-[#93918F] font-thin">{document.companyC || '-'}</p>
            </div>
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">Brand</h5>
                <p className="text-[#93918F] font-thin">{document.dAttribute6C || '-'}</p>
            </div>
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">Divisione</h5>
                <p className="text-[#93918F] font-thin">{document.dAttribute7C || '-'}</p>
            </div>
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">
                    Condizione e Modalità Pagamento
                </h5>
                <p className="text-[#93918F] font-thin flex flex-col gap-y-0.5">
                    <span>{document.dPaymentCondC || '-'}</span>
                    <span>{document.dPaymentModeC || '-'}</span>
                </p>
            </div>
        </div>
    );
};

export default BusinessPartnerCustomerTab;
