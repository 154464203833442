import React from 'react';
import { Document } from 'store/rtk/allApis';

import TableRow from './row';

interface TableBodyProps {
    documents: Document[];
}

const TableBody = ({ documents }: TableBodyProps) => {
    return (
        <div>
            {documents &&
                documents.map((document) => <TableRow document={document} key={document.id} />)}
        </div>
    );
};

export default TableBody;
