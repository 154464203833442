import React from 'react';
import { DocumentItem } from 'store/rtk/allApis';

interface IBusinessPartnerSupplierTabProps {
    document: DocumentItem;
}

const BusinessPartnerSupplierTab = ({ document }: IBusinessPartnerSupplierTabProps) => {
    return (
        <div className="grid grid-cols-4 gap-x-4 gap-y-10 text-sm">
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">Codice Fornitore SAP</h5>
                <p className="text-[#93918F] font-thin">{document.supplier || '-'}</p>
            </div>
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">Società Fornitore</h5>
                <p className="text-[#93918F] font-thin">{document.companyS || '-'}</p>
            </div>
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">Tipo Ripartizione</h5>
                <p className="text-[#93918F] font-thin">{document.dRepartitionType || '-'}</p>
            </div>
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">Gruppo cash managment</h5>
                <p className="text-[#93918F] font-thin">{document.dCashManagGroup || '-'}</p>
            </div>
            <div>
                <h5 className="text-dark-gray_main font-bold mb-2">
                    Condizione e Modalità Pagamento
                </h5>
                <p className="text-[#93918F] font-thin flex flex-col gap-y-0.5">
                    <span>{document.dPaymentCondS || '-'}</span>
                    <span>{document.dPaymentModeS || '-'}</span>
                </p>
            </div>
        </div>
    );
};

export default BusinessPartnerSupplierTab;
