import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import MuiModal from '@mui/material/Modal';
import React from 'react';

interface IModalProps {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    style?: SxProps<Theme>;
}

const defaultStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: 1,
    p: 4,
};

const Modal = ({ children, isOpen, onClose, style }: IModalProps) => {
    return (
        <MuiModal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...defaultStyle, ...style }}>{children}</Box>
        </MuiModal>
    );
};

export default Modal;
