import Root from 'components/root';
import { ROUTES } from 'consts';
import BusinessPartnerPage from 'pages/business-partner';
import CustomerInvoicesPage from 'pages/customer-invoices';
import HomePage from 'pages/home';
import SupplierInvoicesPage from 'pages/supplier-invoices';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
    {
        path: ROUTES.HOME,
        element: (
            <Root>
                <HomePage />
            </Root>
        ),
    },
    {
        path: ROUTES.CUSTOMER_INVOICES,
        element: (
            <Root>
                <CustomerInvoicesPage />
            </Root>
        ),
    },
    {
        path: ROUTES.SUPPLIER_INVOICES,
        element: (
            <Root>
                <SupplierInvoicesPage />
            </Root>
        ),
    },
    {
        path: ROUTES.BUSINESS_PARTNER,
        element: (
            <Root>
                <BusinessPartnerPage />
            </Root>
        ),
    },
]);
