import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticationProvider } from '@microsoft/microsoft-graph-client';

import { getGraphAccessToken } from './graphAuthToken';

export class GraphAuthProvider implements AuthenticationProvider {
    public instance!: IPublicClientApplication;
    public accounts!: AccountInfo[];
    constructor(instance: IPublicClientApplication, accounts: AccountInfo[]) {
        // Constructor
        this.instance = instance;
        this.accounts = accounts;
    }

    public async getAccessToken(): Promise<string> {
        return getGraphAccessToken(this.accounts, this.instance);
    }
}
