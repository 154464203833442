import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import fileImg from 'assets/images/file.png';
import { ROUTES } from 'consts';
import React from 'react';
import { createSearchParams, Link } from 'react-router-dom';

interface IHomeStatusProps {
    errorCount: number;
    query: Record<string, string[]>;
    label: string;
}
const HomeStatus = ({ errorCount, label, query }: IHomeStatusProps) => {
    return (
        <div className="w-40 flex items-center justify-center flex-col">
            {errorCount > 0 ? (
                <>
                    <DangerIcon className="h-12 w-12 mb-4 fill-rose-600" />
                    <Link
                        to={{
                            pathname: ROUTES.CUSTOMER_INVOICES,
                            search: createSearchParams(query).toString(),
                        }}
                        className="max-w-full w-[370px] text-center text-sm text-rose-600 hover:underline"
                    >
                        <p className="font-bold">{`${errorCount} fatture clienti`}</p>
                        {label}
                    </Link>
                </>
            ) : (
                <>
                    <img src={fileImg} alt="File" className="mb-4" />
                    <div className="text-center text-sm text-green-700">
                        <p className="font-bold">Fatture clienti</p>
                        <p>{label}</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default HomeStatus;
